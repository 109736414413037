.list-games {
  .games-list-cards {
    margin-bottom: 3rem;
    padding: 0;
  }

  .games-list-empty {
    text-align: center;
    color: $midnight-blue;
    font-weight: bold;
    margin-bottom: 3rem;
  }

  .paginate {
    text-align: center;
    margin-bottom: 3rem;
  }
}