.subscription {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  color: $white;
  text-align: left;
  font-size: 1.4rem;
  width: 100%;
  padding: 0 12rem 2rem 12rem;
  position: relative;

  .expires-info{
    margin-bottom: 6rem;
    text-align: center;
    color: $midnight-blue;
  }

  .plan {
    margin: 0 -0.9rem;
    position: relative;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.3);
    background-color: $midnight-blue;
    overflow: hidden;

    &__first {
      border-radius: 0.8rem 0 0 0.8rem;
    }

    header {
      background-color: $midnight-blue;
      color: $white;
    }

    .plan-title {
      background-color: rgba(0, 0, 0, 0.5);
      position: relative;
      margin: 0;
      padding: 2rem 2rem 0;
      text-transform: uppercase;
      letter-spacing: 0.4rem;

      &:after {
        position: absolute;
        content: '';
        top: 100%;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4rem 37rem 0 0;
        border-color: rgba(0, 0, 0, 0.5) transparent transparent;
      }
    }

    .plan-cost {
      padding: 4rem 2rem 1rem;
      text-align: right;
    }

    .plan-price {
      font-weight: 600;
      font-size: 3em;
    }

    .plan-type {
      opacity: 0.8;
      font-size: 1.4em;
      text-transform: uppercase;

      span {
        text-decoration-line: line-through; 
      }
    }

    .plan-features {
      padding: 0 0 2rem;
      margin: 0;
      list-style: outside none none;

      li {
        padding: 0.8rem 5%;

        line-height: 1.6rem span {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      i {
        margin-right: 0.8rem;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .plan-select {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding: 2rem;
      text-align: center;

      .button {
        background-color: rgba(0, 0, 0, 0.3);
        color: $white;
        text-decoration: none;
        padding: 12px 2rem;
        font-size: 0.75em;
        font-weight: 600;
        border-radius: 2rem;
        text-transform: uppercase;
        letter-spacing: 0.4rem;
        display: inline-block;
        border: 0;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .featured {
    margin-top: -2rem;
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.4);
    z-index: 1;
    border-radius: 0.8rem;

    .plan-select {
      padding: 4rem 2rem;
    }

    @media (max-width: $xs-breakpoint) {
      margin-top: 0;

      .plan-select {
        padding: 2rem;
      }
    }
  }

  @media (max-width: $md-breakpoint) {
    padding: 2rem 0;

    .plan {
      border-radius: 0.8rem;
      margin: auto auto 5rem auto;
      max-width: 40rem;
    }
  }
}