.square {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  margin-top: 0.6rem;
  float: left;
}

.player-header {
  background: url('/images/bg_2.jpg');
  background-size: cover;
  background-position: center center;
  margin: 0;
  height: 100%;
  border-top: 0.3rem solid $silver;
  box-shadow: 0 -10px 10px -10px #999 inset;

  .carrossel-items {
    height: 35rem;
  }

  .icons {
    float: left;

    font-size: 1.5rem;
    margin-top: 0.4em;
    margin-bottom: 0.8rem;

    .cateogry {
      position: relative;
      float: left;
      height: 1.5rem;

      svg {
        position: absolute;
      }
    }
  }

  .profile {
    position: relative;
    padding: 5rem 0;

    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 3rem;

      .level {
        display: flex;
        color: $pomegranate;
        font-size: 9rem;
        line-height: 9rem;
        font-weight: 900;
        letter-spacing: -1rem;
        padding-right: 2rem;
      }

      .texts {
        .name {
          color: $wet-asphalt;
          padding-top: 0.5rem;
          font-size: 5rem;
          line-height: 5rem;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: -0.2rem;
        }

        .reputation {
          color: $pomegranate;
          padding-top: 0rem;
          font-size: 2.4rem;
          line-height: 2.4rem;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: -0.1rem;
        }
      }

      &.sm {
        .level {
          font-size: 8.2rem;
          line-height: 8.2rem;
        }

        .texts {
          .name {
            font-size: 4.2rem;
            line-height: 4.2rem;
          }
        }
      }

      &.md {
        .level {
          font-size: 7.3rem;
          line-height: 7.3rem;
        }

        .texts {
          .name {
            font-size: 3.3rem;
            line-height: 3.3rem;
          }
        }
      }

      &.lg {
        .level {
          font-size: 7rem;
          line-height: 7rem;
        }

        .texts {
          .name {
            font-size: 3rem;
            line-height: 3rem;
          }
        }
      }
    }

    .picture {
      position: relative;
      height: 20rem;
      text-align: center;

      .photo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 18rem;
        width: 18rem;
        margin: 1rem auto;
        padding: 0.5rem;
        border-radius: 50%;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.7);

        .blocked,
        .disable {
          position: absolute;
          background: rgba(192, 57, 43, 0.8);
          width: calc(100% - 1rem);
          height: calc(100% - 1rem);
          font-weight: bold;
          text-transform: uppercase;
          z-index: 1;
          border-radius: 50%;
          overflow: hidden;

          .text {
            font-size: 1.4rem;
            color: $clouds;
            line-height: 4rem;
            margin: calc(50% - 2rem) 0;
            background: $black;
          }
        }

        .blocked {
          background: rgba(192, 57, 43, 0.6);
        }

        .disable {
          background: rgba(127, 140, 141, 0.6);
        }

        >img {
          border-radius: 50%;
          height: 100%;
          width: auto;
        }
      }

      .progress {
        height: 100%;
        width: 100%;
      }
    }
  }

  .info {
    padding: 5rem 0;

    .item {
      float: left;
      margin: 0 4rem 2rem 0;

      .title {
        color: $belize-hole;
        text-transform: uppercase;
        font-size: 1.1rem;
        line-height: 1.1rem;
      }

      .value {
        color: $wet-asphalt;
        margin-top: 0.3rem;
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 900;

        .icons {
          margin: 0;
          margin-top: -0.1rem
        }
      }
    }
  }

  .bets {
    padding: 5rem 0;

    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 1.5rem;

      .score-value {
        display: flex;
        color: $pomegranate;
        font-size: 6rem;
        line-height: 6rem;
        font-weight: 900;
        padding-right: 1.5rem;
        letter-spacing: -1rem;
      }

      .texts {
        .score-text {
          color: $wet-asphalt;
          padding-top: 0.6rem;
          font-size: 3rem;
          line-height: 3rem;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: -0.2rem;
        }

        .round {
          color: $pomegranate;
          padding-top: 0rem;
          font-size: 1.8rem;
          line-height: 1.8rem;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: -0.1rem;
        }
      }
    }

    .game {
      width: 50%;
      float: left;
      padding: 0.3rem 1rem 0 1rem;

      .label {
        display: flex;
        flex-direction: row;
        font-size: 1.2rem;
        line-height: 1.2rem;
        height: 1.2rem;
        overflow: hidden;
        font-weight: 700;

        * {
          -o-transition: all 0 ease-out;
          transition: none;
        }

        .teams {
          display: flex;
          flex-direction: row;
          text-align: left;
          width: calc(100% - 3rem);

          div {
            float: left;
          }

          .result {
            margin: 0 0.2rem;
          }

          .name {
            display: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .abbr {
            display: block;
          }
        }

        .score {
          text-align: right;
          width: 3rem;
        }
      }

      .progress {
        width: 100%;
        height: 1rem;
        margin-bottom: 2rem;

        &.in-progress {
          opacity: 0.6;
        }
      }

      &:hover {
        .label {
          .teams {
            width: 100%;

            .name {
              display: block;
            }

            .abbr {
              display: none;
            }
          }

          .score {
            display: none;
          }
        }
      }
    }
  }
}

.bets-none {
  text-align: center;
  width: 100%;
  padding: 10rem 0;
  color: $concrete;

  svg {
    font-size: 5rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: $md-breakpoint) {
  .player-header {
    .profile {
      padding: 3rem 0;

      .title {
        margin-left: 9.6rem;
        width: calc(100% - 9.6rem);
        padding-bottom: 0rem;

        .level {
          display: none;
        }

        .texts {
          .name {
            font-size: 2.6rem;
            line-height: 3rem;
          }

          .reputation {
            font-size: 1.6rem;
            line-height: 1.6rem;
          }
        }

        &.sm,
        &.md,
        &.lg {
          .texts {
            .name {
              font-size: 2.6rem;
              line-height: 3rem;
            }
          }
        }
      }

      .picture {
        margin: 3rem 0;
        position: absolute;
        height: 8rem;
        width: 8rem;
        top: 0;
        left: 0;

        .photo {
          height: 7.2rem;
          width: 7.2rem;
          margin: 0.4rem auto;
          padding: 0.3rem;

          .blocked,
          .disable {
            width: calc(100% - 0.6rem);
            height: calc(100% - 0.6rem);

            .icon {
              line-height: 6.6rem;
              font-size: 3.6rem;
              color: $clouds;
            }
          }
        }
      }
    }

    .info {
      padding: 1rem 0;

      .item {
        margin: 0 1rem 2rem 0;
        min-width: calc(50% - 1rem);
      }
    }

    .bets {
      padding: 1rem 0;
      overflow: hidden;

      .title {
        width: 22rem;
        margin: auto;

        .score-value {
          text-align: right;
          width: 8rem;
          display: block;
        }

        .texts {
          width: 14rem;
        }
      }
    }
  }
}

.player-overview {
  .modes {
    position: relative;
    padding: 3rem 0;
    max-width: 90rem;
    margin: auto;
  }

  .infos {
    position: relative;
    margin: auto;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.7rem;
    text-align: center;

    >span {
      margin: 0 0.7rem;
      text-align: center;
    }
  }

  .statistics {
    position: relative;
    padding: 4rem 1rem;
    background: url('/images/bg_noise.jpg') repeat;
    color: $clouds;

    .title-line {
      color: $clouds;

      &:after {
        border-color: $clouds;
      }
    }

    .top {
      height: 30rem;
      position: relative;
    }

    .bottom {
      position: relative;
    }

    .gutter {
      >div {
        padding: 0 3rem;
      }
    }

    .carousel {
      height: 33.5rem;
      position: relative;
    }

    .discard {
      padding: 0 1.2rem;

      .scores {
        position: relative;
        margin: 0 0.6rem;

        >div {
          float: left;
          text-align: center;
        }
      }

      .chart {
        height: 12rem;
        width: 100%;
      }
    }

    .valuation {
      padding: 0 1.2rem;

      .chart {
        margin: 0 2rem;
        height: 15rem;
      }

      .message {
        text-align: center;
        margin: 6rem 0;
      }
    }

    .achievements {
      padding: 0 1.2rem;

      .progress {
        padding: 0.5rem 4rem;
        height: 2.4rem;
        margin: 1rem 0;
      }

      .categories {
        display: flex;
        padding: 1rem 2rem;

        .category {
          width: 50%;
          text-align: center;

          .value {
            height: 3.4rem;
            font-size: 3.2rem;
            line-height: 3.2rem;
            font-weight: 900;

            span {
              font-size: 1.4rem;
            }
          }

          .label {
            text-transform: uppercase;
            font-weight: 900;
            font-size: 1.3rem;
          }

          .text {
            font-weight: bold;
            font-size: 1.2rem;
          }
        }
      }
    }

    .reputation {
      .badge {
        text-align: center;

        .image {
          width: 100%;
          height: 25.6rem;
        }

        .name {
          width: 100%;
          font-weight: bold;
          font-size: 2.5rem;
        }
      }

      .information {
        text-align: center;

        .result-title {
          text-align: center;
          margin-bottom: 1rem;
        }

        .progress {
          padding: 0.5rem 4rem;
          height: 2.4rem;
          margin-bottom: 1rem;
        }

        .score-chart {
          height: 10rem;
          width: 100%;
          display: inline-flex;
          margin-bottom: 0.5rem;
          padding-right: 4rem;

          .texts {
            width: 50%;
            margin: 1.3rem;

            div {
              line-height: 2.5rem;
              font-size: 1.5rem;
              font-weight: bold;
              text-align: right;
            }
          }

          .chart {
            width: 50%;
            height: 100%;
          }
        }

        .calculation {
          text-align: center;
          font-size: 1.2rem;
        }

        .next {
          display: inline-flex;

          .score {
            font-size: 3rem;
            letter-spacing: -0.1rem;
            font-weight: bold;
            margin: 0.5rem 0;

            span {
              font-size: 8rem;
            }
          }

          .icon {
            margin: 4rem 1rem 4rem 3rem;
            font-size: 4rem;
          }

          .next-badge {
            position: relative;

            .result-icon {
              font-size: 8rem;
              margin: 1rem 1.5rem;
            }

            .image {
              height: 12.8rem;

              img {
                height: 100%;
                width: auto;
              }
            }

            .name {
              position: absolute;
              width: 100%;
              bottom: 0.5rem;
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .bets {
      .content {
        .row {
          position: relative;
          display: inline-flex;
          height: 10rem;
          width: 100%;
          margin-bottom: 2rem;

          .bet-chart {
            width: 12rem;
            text-align: center;

            .empty {
              font-size: 5rem;
              padding: 1.2rem;
            }

            .chart {
              width: 9rem;
              height: calc(100% - 2.5rem);
              margin: auto;
            }

            .title {
              height: 2.5rem;
              font-weight: bold;
              text-transform: uppercase;
            }

            .position {
              margin-top: 0.5rem;
            }
          }

          .texts {
            width: calc(100% - 12rem);
            padding: 0.5rem;

            .info {
              position: relative;
              display: inline-flex;
              width: 100%;
              font-weight: bold;

              .label {
                width: calc(100% - 7rem);
                text-transform: uppercase;
                font-size: 1.4rem;
                line-height: 3.3rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.title {
                  font-size: 1.8rem;
                }
              }

              .value {
                width: 7rem;
                font-size: 2.4rem;
                text-align: right;
                line-height: 3.3rem;
              }
            }
          }

          .types {
            width: calc(100% - 20rem);
            padding: 0.5rem;

            .info {
              position: relative;
              display: inline-flex;
              width: 100%;
              font-weight: bold;

              .label {
                text-transform: uppercase;
                font-size: 1.4rem;
                line-height: 3.3rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .high {
            padding: 1rem 0;
            text-align: right;
            width: 8rem;
            overflow: hidden;

            .value {
              font-size: 5rem;
              line-height: 5rem;
              font-weight: bold;
            }

            .label {
              font-size: 1.3rem;
              line-height: 2rem;
              text-transform: uppercase;

              span {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $md-breakpoint) {
  .player-overview {
    .statistics {
      .carousel {
        height: 40rem;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .player-overview {
    .statistics {
      .bets {
        .content {
          .row {
            .texts {
              .info {
                .label {
                  font-size: 1.2rem;

                  &.title {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.player-bets {
  text-align: center;

  >.content-container {
    padding: 0;
  }

  .rounds {
    text-align: left;
    margin-bottom: 1rem;

    .text {
      font-size: 1.6rem;
      line-height: 3rem;
      font-weight: bold;
    }

    .open {
      .button {
        margin-right: 1rem;
      }

      .message {
        font-size: 1.4rem;
      }
    }

    .history {
      float: right;
      margin-top: 1.1rem;
    }
  }

  .competitions-title {
    background: $white;
    margin-bottom: 0.5rem;
    text-align: center;
    padding: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $concrete;
    border: 0.1rem solid $silver;

    &.score {
      color: $black;
      font-weight: 900;
    }
  }

  .games {
    .game {
      position: relative;
      overflow: hidden;
      padding: 1.6rem;
      background: $white;
      margin-bottom: 0.5rem;
      border: 0.1rem solid $silver;

      .teams-names {
        display: none;
      }

      .bet,
      .bet-score {
        display: flex;
        flex-direction: row;

        .team {
          width: calc(50% - 7rem);
          color: $black;
          font-size: 1.8rem;
          line-height: 4rem;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          img {
            display: inline-block;
            width: 15.6rem;
            height: auto;
            vertical-align: middle;
            position: absolute;
            top: -2rem;
            opacity: 1;
          }

          &.home {
            text-align: right;

            img {
              left: 1rem;
            }
          }

          &.away {
            text-align: left;

            img {
              right: 1rem;
            }
          }
        }

        &:focus-within {
          .team {
            img {
              opacity: 0.3;
            }
          }
        }

        .result-form {
          width: 14rem;
          height: 4rem;
          padding: 0 1rem;

          div {
            float: left;
            width: 4rem;
            font-size: 2.4rem;
            line-height: 4rem;
            font-weight: bold;
            color: $silver;
            text-align: center;
          }

          input {
            float: left;
            width: 4rem;
            height: 100%;
            border: 0.1rem solid $silver;
            outline: none;
            padding: 0.5rem;
            border-radius: 0.3rem;
            font-weight: normal;
            text-align: center;
          }
        }
      }

      .bet-score {
        .team {
          line-height: 2.4rem;
        }

        .result {
          width: 14rem;
          height: 2.4rem;
          padding: 0 3rem;
          font-size: 2.4rem;
          line-height: 2.4rem;

          .value {
            float: left;
            width: 3rem;
            font-weight: bold;
            color: $asbestos;
            text-align: center;
          }

          .separator {
            float: left;
            width: 2rem;
            font-weight: bold;
            color: $silver;
            text-align: center;
          }

          &.in-progress {

            .value,
            .separator {
              color: $peter-river;
            }
          }
        }
      }

      .info {
        width: 100%;
        text-align: center;
        color: $concrete;
        margin-top: 0.7rem;
        font-size: 1.2rem;
      }

      svg {
        margin-left: 1rem;
        margin-right: 0.4rem;
      }

      .score {
        position: relative;
        width: 7rem;
        margin: auto;
        font-weight: 700;
        text-align: center;
        line-height: 2rem;
        font-size: 1.2rem;
        bottom: -0.5rem;
        padding: 0 1rem;
        color: #ecf0f1;
        border-radius: 0.2rem;
        background: $peter-river;

        &.canceled {
          width: 8rem;
        }
      }     

      .score-update-top {
        width: 100%;
        text-align: center;
        color: $concrete;
        margin-bottom: 0.7rem;
        font-size: 1.2rem;
      }

      .status-form {
        width: 100%;
        text-align: center;
        color: $concrete;
        margin-top: 0.7rem;

        select {
          width: 12rem;
          height: 100%;
          border: 0.1rem solid $silver;
          outline: none;
          padding: 0.5rem;
          border-radius: 0.3rem;
          font-weight: normal;
          text-align: center;
          font-size: 1.2rem;
        }
      }

      &:hover {
        .team {
          img {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .button-bet {
    margin-top: 2rem;
  }
}

@media (max-width: $md-breakpoint) {
  .player-bets {
    .rounds {
      .history {
        float: none;

        .paginate {
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: $md-breakpoint) {
  .player-bets {

    .rounds {
      margin-top: 0;

      .open {
        .message {
          font-size: 1.3rem;
        }
      }
    }

    .competitions-title {
      border-left: none;
      border-right: none;
    }

    .games {
      .game {
        padding: 1.6rem 6rem;
        border-left: none;
        border-right: none;

        .bet {
          .team {
            &.home {
              img {
                left: -9rem;
              }
            }

            &.away {
              img {
                right: -9rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .player-bets {

    .rounds {
      .open {
        button {
          font-size: 1.5rem;
          padding: 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .games {
      .game {
        padding: 1.6rem 6rem;

        .bet {
          .team {
            &.home {
              img {
                left: -9rem;
              }
            }

            &.away {
              img {
                right: -9rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .player-bets {
    .games {
      .game {

        .teams-names {
          display: block;
          color: $black;
          font-size: 1.6rem;
          line-height: 1.6rem;
          font-weight: bold;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: -0.1rem;
          margin-bottom: 1rem;

          span {
            color: $concrete;
          }
        }

        .bet {
          .team {
            span {
              display: none;
            }

            img {
              top: -0.5rem;
            }
          }
        }
      }
    }
  }
}

.player-variation {
  line-height: 1.2rem;
  font-size: 1.2rem;

  &.up {
    color: $nephritis;
  }

  &.down {
    color: $pomegranate;
  }
}