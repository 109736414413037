.ranking-position {
  margin-bottom: 2rem;

  .content {
    display: table;

    .picture {
      display: table-cell;

      img {
        width: 10rem;
        height: auto;
        margin-right: 2rem;
        vertical-align: middle;
      }
    }

    .info {
      display: table-cell;
      vertical-align: middle;

      .text {
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.7rem;
      }

      .button {
        margin-top: 0.5rem;
        background: $pomegranate;
        padding: 0.4rem 2rem;
        font-size: 1.6rem;

        &:hover {
          background: $wet-asphalt;
        }
      }
    }

    &.player {
      .picture {
        img {
          border: 0.3rem solid $wet-asphalt;
          border-radius: 50%;
        }
      }
    }
  }
}

.ranking-buttons {
  overflow: auto;

  .types {
    margin-right: 0.5rem;
    background-color: $wet-asphalt;
    width: 16rem;
  }

  >* {
    float: left;

    &:hover {
      background-color: $pomegranate;
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .ranking-buttons {
    margin-bottom: 2rem;
  }
}
