mark {  
  border: 2px solid $white;
  background-color: $green-sea;
  color: $white;
  border-radius: 50%; 
  width: 2rem;
  height: 2rem;  
  position: absolute;
  top: 0.2rem;
  right: 0;
  font-size: 1rem;
  line-height: 1.8rem;
  font-weight: 400;
  font-weight: 700;
  text-align: center;

  &.alert {
    background-color: $alizarin;
  }

  &.info {
    background-color: $belize-hole;
  }
}