.player-game-bet{
  img {
    width: 100%;
    border-radius: 2rem;
  }
  p {
    font-size: 1.3rem;
    margin: 0;
    width: 100%;
    text-align: center;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.player-image{
  height: 5rem;
  border-radius: 50%;
  border: 0.2rem solid $white-complete;

  &:hover {
    border: 0.4rem solid $midnight-blue;
  }
}