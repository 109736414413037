.market-list {
  text-align: center;
}

.market-card {
  .name {
    color: $wet-asphalt;
    font-size: 2rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: -0.2rem;
    text-align: center;
    margin: 0.6rem 0;
  }

  .images {
    width: 100%;
    display: table;

    >* {
      display: table-cell;
    }

    img {
      width: 100%;
      height: auto;
    }

    .player {
      width: 35%;

      img {
        border-radius: 50%;
      }
    }

    .team {
      width: 35%;
    }

    .icon {
      width: 30%;
      text-align: center;
      vertical-align: middle;
      font-size: 2.2rem;

      &.out {
        color: $pomegranate;

        svg {
          transform: rotate(180deg);
        }
      }

      &.in {
        color: $green-sea;
      }

      div {
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: -0.1rem;
        text-transform: uppercase;

        &.round {
          text-transform: none;
          color: $asbestos;
        }
      }
    }
  }
}