.not-found {
  background: #fff;
  padding: 3rem 0;

  .four_zero_four_bg {
    background-image: url('/images/not_found.gif');
    background-repeat: no-repeat;
    background-position: center -8rem;

    height: 38rem;
    max-width: 75rem;
    width: 100%;
    margin: auto;

    .title {
      width: 100%;
      text-align: center;
      font-size: 8rem;
      margin-bottom: 1rem;
      font-weight: 700;
    }
  }

  .contant_box_404 {
    text-align: center;

    h3 {
      font-size: 3rem;
      text-align: center;
    }

    p {
      margin-top: 0;
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .not-found { 
    .contant_box_404 {  
      h3 {
        font-size: 2.4rem;
      }
  
      p {
        font-size: 1.3rem;
      }
    }
  }
}