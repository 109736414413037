.records-supporters {
  .table-supporters {
    padding-right: 2rem;
    min-width: '12rem';
    display: 'flex';

    table {
      margin-bottom: 3rem;

      .precentage-supporters {
        display: flex;
        min-width: 12rem;

        .value {
          float: left;
          width: 4rem;
        }

        .line {
          float: left;
          width: calc(100% - 4rem);
          height: 1.2rem;
          padding: 0 2rem;
          margin: 0.4rem 0;
        }
      }
    }

    .paginate {
      margin-bottom: 4rem;
    }
  }

  .badges {
    float: left;
    width: 3.22rem;
    margin-right: 1rem;
    margin-top: 6.4rem;

    img {
      width: 100%;
    }
  }

  .charts {
    padding-left: 2rem;
    padding: 0 1.6rem;
    margin-bottom: 3rem;

    .button {
      margin: 0 0.5rem;

      &.active {
        background: $green-sea;
      }
    }

    .words {
      width: 100%;
      height: 55rem;
      margin-top: 5rem;
    }

    .chart {
      float: left;
      height: 60rem;
      width: calc(100% - 4.22rem);
    }
  }
}

@media (max-width: $md-breakpoint) {
  .records-supporters {
    .table-supporters {
      padding-right: 0;
    }
  }
}

.reputation {
  .levels {
    max-width: 90rem;
    margin: 0 auto;
  }

  .paginate {
    margin-top: 2rem;
  }
}

.rounds-statistics-page {
  .pagination {
    text-align: center;
  }

  .bets {
    position: relative;
    display: inline-flex;
    margin: auto;
    margin: 1rem 0;
    max-width: 30rem;

    .types {
      width: calc(100% - 12rem);
      padding: 1rem 0 1rem 2rem;

      .data {
        position: relative;
        display: inline-flex;
        width: 100%;
        font-weight: bold;

        .label {
          text-transform: uppercase;
          font-size: 1.4rem;
          line-height: 3.3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .chart-bets {
    height: 12rem;
    width: 12rem;
    float: left;
  }

  .general {
    margin-bottom: 3rem;

    .bets-per {
      font-weight: bold;
      font-size: 1.5rem;
      display: flex;
      justify-content: space-between;
    }

    .bets-per-bar {
      width: 100%;
      height: 1rem;
      ;
      margin-bottom: 2rem;
    }
  }

  .game {
    margin-bottom: 3rem;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      text-align: center;
      border-bottom: 0.4rem solid $midnight-blue;
      font-weight: 900;
    }

    .result {
      height: 30rem;

      .info {
        text-align: center;

        .numbers {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          font-size: 1.3rem;
          color: $wet-asphalt;
          font-weight: bold;

          svg {
            margin-right: 0.5rem;
            margin-left: 1rem;
          }
        }

        .accurates {
          text-align: center;

          &.canceled {
            margin-top: 11rem;
          }

          .sub-title {
            font-weight: bold;
            font-size: 1.4rem;
            margin-bottom: 0.5rem;

            &.space {
              margin-top: 3rem;
            }
          }

          img {
            margin: 0 0.2rem;
          }
        }
      }
    }
  }

  .ranking {
    .pagination {
      text-align: right;
      margin: 3rem 0 4rem 0;
    }
  }
}