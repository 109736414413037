.card-all {
  .badge {
    .level {
      font-family: "Arial", sans-serif;
      position: absolute;
      background: $clouds;
      border: 2px solid $white-complete;
      color: $asbestos;
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      font-size: 0.9rem;
      line-height: 1.6rem;
      width: 1.8rem;
      height: 1.8rem;
      right: -0.5rem;
      bottom: -0.5rem;
      z-index: 2;

      &.prime {
        background-color: $orange;
        color: $clouds;
      }

      &.bet {
        background-color: $green-sea;
        color: $clouds;
      }
    }

    &.round {
      img {
        border-radius: 50%;
      }

      &:after {
        position: absolute;
        content: '';
        border: 2px solid $white-complete;
        display: block;
        border-radius: 50%;
        top: -0.2rem;
        bottom: -0.2rem;
        left: -0.2rem;
        right: -0.2rem;
      }
    }
  }

  .texts {
    .name {
      position: relative;
      color: $wet-asphalt;
      padding: 0.1rem 0 0 0;
      font-size: 2rem;
      line-height: 2rem;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: -0.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0.2rem;

      &.sm {
        font-size: 1.9rem;
      }

      &.md {
        font-size: 1.8rem;
      }

      &.lg {
        font-size: 1.6rem;
      }
    }

    .tag {
      position: relative;
      color: $pomegranate;
      padding: 0;
      padding-bottom: 0.5rem;
      font-size: 1.3rem;
      line-height: 1.3rem;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: -0.1rem;
      padding-right: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.prime {
        color: $orange;
      }

      .label {
        float: left;
      }

      .icons {
        &.teams {
          display: none;
        }
      }
    }
  }

  &:hover {
    .texts {
      .tag {
        .icons {
          &.all {
            display: none;
          }
          &.teams {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .card-all {
    .texts {
      .name {
        &.sm {
          font-size: 1.8rem;
        }

        &.md {
          font-size: 1.6rem;
        }

        &.lg {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.card-default {
  width: 100%;

  .badge {
    position: relative;
    height: 6rem;
    width: 6rem;
    margin: auto;

    img {
      height: 100%;
      width: auto;
    }

    .level {
      font-size: 1.2rem;
      line-height: 2rem;
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .texts {
    margin-top: 1rem;
    text-align: center;

    .icons-field {
      text-align: center;
      overflow: hidden;

      .icons {
        .cateogry {
          height: 2rem;

          svg {
            font-size: 1.6rem;
            margin: auto -0.3rem;
          }

          img {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
}

.card-champion {
  width: 100%;
  background: url('/images/podium.png') center no-repeat;
  height: 24rem;
  padding: 3rem;

  .badge {
    position: relative;
    height: 11rem;
    width: 11rem;
    margin: auto;

    img {
      height: 100%;
      width: auto;
      filter: drop-shadow(0.4rem 0.4rem 0.8rem #222);
    }
  }
}

.card-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 3.6rem;

  .badge {
    position: relative;
    display: flex;
    height: 100%;

    img {
      height: 100%;
      width: 3.6rem;
    }
  }

  .texts {
    padding-left: 1rem;
    width: calc(100% - 3.6rem);

    .name {
      position: relative;
      text-align: left;

      .name {
        float: left;
      }

      .icons {
        float: left;
        margin-left: 0.5rem;
      }
    }

    .tag {
      text-align: left;
    }
  }

  .icons {
    float: left;

    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.1rem;

    .cateogry {
      position: relative;
      float: left;
      height: 1rem;

      svg {
        left: 0;
        position: absolute;
      }

      img {
        margin-right: 0.2rem;
      }
    }
  }

  &:hover {
    .texts {

      .name {
        color: $pomegranate;
      }

      .tag {
        color: $wet-asphalt;
      }
    }
  }
}

.card-light {
  transform: scale(0.8);

  .badge {
    .level {
      display: none;
    }
  }

  .texts {
    .name {
      color: $white;
      font-weight: 500;
      letter-spacing: 0;
    }

    .tag {
      color: $silver;
    }
  }
}

.card-squad {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 5rem;

  .badge {
    position: relative;
    width: 14rem;
    height: 5rem;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: -3rem;
    }
  }

  .texts {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    width: calc(100% - 14rem);
  }
}

.card-table-inverted {
  .card-table {
    .badge {
      order: 2;
    }

    .texts {
      order: 1;
      padding-left: 0;
      padding-right: 1rem;

      .name {
        text-align: right;

        .name {
          float: right;
          text-align: right;

        }

        .icons {
          float: right;
        }
      }

      .tag {
        text-align: right;

        .label {
          float: right;
        }

        .icons {
          float: right;
          margin-left: 0;
          margin-right: 0.5rem;
        }
      }
    }

    .icons {
      float: right;
    }
  }
}

.card-highlight {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 8rem;

  .badge {
    position: relative;
    display: flex;
    height: 100%;
    padding: 0 2rem;

    img {
      height: 100%;
      width: auto;
    }
  }

  .texts {
    padding: 1.6rem 2rem;
    width: calc(100% - 12rem);

    .name {
      position: relative;
      text-align: left;
      font-size: 3rem;
      line-height: 3rem;
      text-transform: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .name {
        float: left;
      }

      .icons {
        float: left;
        margin-left: 0.5rem;
      }
    }

    .tag {
      text-align: left;
      font-size: 1.4rem;
      line-height: 1.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icons {
    float: left;

    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.1rem;

    .cateogry {
      position: relative;
      float: left;
      height: 1rem;

      svg {
        left: 0;
        position: absolute;
      }

      img {
        margin-right: 0.2rem;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .card-highlight {

    .badge {
      padding: 0;
    }

    .texts {
      padding: 1.6rem 1rem;
      width: calc(100% - 8rem);

      .name {
        font-size: 2.6rem;
      }
    }
  }
}

.card-simple {
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.15);
  background: $white;
  padding: 2rem;
  position: relative;
  border-radius: 0.3rem;
  margin: auto;
  margin-bottom: 2rem;
}

.real-team-card {
  .badge {
    box-shadow: inset 0px 1.5rem 2rem -1.5rem #00000055;

    img {
      width: 80%;
      height: auto;
      left: 10%;
      filter: drop-shadow(0.2rem 0.2rem 0.4rem #222);
    }
  }

  .texts {
    .name {
      line-height: 3.6rem;
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .real-team-card {
    .badge {
      img {
        left: 4%;
      }
    }
  }
}