.competitions-details {
  .filter-select {
    width: 20rem;
  }

  .winners-header {
    position: relative;
    padding: 2rem 1rem 0 1rem;
    background: $black url('/images/team_badges.jpg') no-repeat;
    background-position: center;
    background-size: cover;
  }

  .info {
    text-align: center;
    position: relative;

    img {
      -webkit-filter: drop-shadow(0.4rem 0.4rem 0.8rem #222);
      filter: drop-shadow(0.4rem 0.4rem 0.8rem #222);
    }

    h2 {
      margin: 1rem;
      position: relative;
      color: #555;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 7rem;
      font-weight: 800;
      line-height: 1;
      text-shadow:
        0.2rem 0 0 $white, -0.2rem 0 0 $white, 0 0.2rem 0 $white,
        0 -0.2rem 0 $white, 0.1rem 0.1rem $white, -0.1rem -0.1rem 0 $white,
        0.1rem -0.1rem 0 $white, -0.1rem 0.1rem 0 $white,
        0.4rem 0.4rem 0.8rem #222;
    }
  }

  .table-labels {
    overflow: auto;
    display: flex;
    margin-top: 1rem;

    div {
      float: left;
      padding: 0.3rem 0.8rem;
      font-size: 1.1rem;
      line-height: 1.2rem;
      font-weight: bold;
      color: #fff;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: .2rem;
      margin: 0.5rem;
      text-transform: uppercase;

      &.champion {
        background-color: $wet-asphalt;
      }

      &.final {
        background-color: $peter-river;
      }

      &.promotion {
        background-color: $green-sea;
      }

      &.repechage {
        background-color: $wisteria;
      }

      &.relegation {
        background-color: $pomegranate;
      }

      &.competition-1 {
        background-color: $amethyst;
      }

      &.competition-2 {
        background-color: $orange;
      }
    }
  }

  .paginate {
    margin-bottom: 1rem;
  }

  .competition-games {
    .paginate {
      margin-top: 3.9rem;
    }
  }

  .classificatory {
    margin-top: 2rem;
  }

  .contenst {
    .information {
      font-size: 1.6rem;
      line-height: 3rem;
      font-weight: bold;
      text-align: center;
      border-radius: 0.3rem;
      background-color: #2980b9;
      color: #f8f8f8;
      width: 12rem;
      margin: auto;
    }
  }

  .header-games {
    padding-top: 0.7em;
    padding-bottom: 0.7rem;
    display: flex;
    justify-content: space-between;

    .title {
      padding-left: 0.4rem;
      font-weight: bold;
      font-size: 2rem;
      line-height: 2rem;

      span {
        margin-left: 1rem;
        opacity: 0.5;
        font-size: 1.3rem;
      }
    }

    .filter-select {
      height: 2.2rem;
      width: 12rem;

      select {
        height: 2rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }

  .play-off {
    .confrontations {
      .confrontation {
        margin-top: 2rem;
        margin-right: 2rem;
        padding: 2rem 1rem;

        &:after {
          position: absolute;
          content: '';
          right: -2rem;
          width: 2rem;
          height: calc(50% + 1rem);
          border: 0.2rem solid $concrete;
          border-left: 0;
        }

        &:nth-child(even) {
          &:after {
            top: -1rem;
            border-top: 0;
          }
        }

        &:nth-child(odd) {
          &:after {
            top: 50%;
            border-bottom: 0;
          }
        }

        &.last {
          margin-right: 0;

          &:after {
            content: none;
          }
        }

        &.final {
          border: 0.3rem solid $wet-asphalt;
          background: $white-complete;
        }

        .title-third {
          font-size: 1.2rem;
          text-align: center;
          font-weight: bold;
        }

        .data {
          display: flex;
          flex-direction: row;

          >.team {
            width: calc(50% - 6rem);
            opacity: 1;

            &.loser {
              opacity: 0.5;
            }
          }

          >.result {
            width: 12rem;
            margin-top: 0.5rem;

            .score {
              display: flex;
              font-size: 3rem;
              font-weight: 900;
              color: $asbestos;
              letter-spacing: -0.04em;

              .goals {
                width: 5.2rem;

                &:first-child {
                  text-align: right;
                }

                &.winner {
                  color: $black;
                }

                span {
                  display: inline-block;
                  vertical-align: middle;
                  line-height: 3rem;
                  font-size: 1.3rem;
                  margin: 0 0.4rem;
                }
              }

              .divisor {
                text-align: center;
                width: 1.6rem;
                line-height: 4.8rem;
                font-size: 2.2rem;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    .penalties {
      .serie {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.7rem;

        >div {
          opacity: 0.4;
        }

        .icon {
          width: 1.4rem;
          margin: 0 0.4rem;
        }

        .value {
          display: none;
          font-weight: bold;
          width: 2rem;
          margin: 0 0.4rem;
        }

        .kicker {
          width: calc(50% - 2.4rem);
          font-weight: bold;
          color: $wet-asphalt;
          font-size: 1.2rem;

          &:first-child {
            text-align: right;
          }

          &:last-child {
            text-align: left;
          }

          a {
            font-weight: bold;
            color: $wet-asphalt;
            font-size: 1.2rem;

            &:hover {
              opacity: 0.4;
            }
          }
        }

        &.home-goal {
          >div {
            &:nth-child(-n+3) {
              opacity: 1;
            }
          }
        }

        &.away-goal {
          >div {
            &:nth-last-child(-n+3) {
              opacity: 1;
            }
          }
        }

        &.goalkeeper {
          >div {
            opacity: 1;
          }
        }
      }

      &.whith-goalkeeper {
        .serie {
          .value {
            display: block;
          }

          .kicker {
            width: calc(50% - 5rem);
          }
        }
      }
    }
  }

  .competition-statistics {
    .paginate {
      margin-top: 2rem;
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .competitions-details {
    .play-off {
      .confrontations {
        .confrontation {
          margin-right: 0;

          &:after {
            content: none;
          }
        }
      }
    }
  }
}