.user-picture {
  margin: 5rem auto;
  height: 25.6rem;
  width: 25.6rem;
  position: relative;
  border-radius: 12.8rem;
  overflow: hidden;

  img {
    height: 25.6rem;
    width: 25.6rem;
  }

  &:hover {
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .3);
      border-radius: 12.8rem;
    }

    &:after {
      content: 'Clique para mudar a Foto. Apenas .jpg';
      color: white;
      text-align: center;
      font-weight: bold;
      width: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.birth-date {
  width: calc(50% - 3rem);
}

.react-datepicker-component {
  .react-datepicker-input {
    input {
      cursor: pointer;
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .birth-date {
    width: 100%;
  }
}

.gender {
  overflow: hidden;
  margin-bottom: 2rem;
  text-align: center;

  button {
    text-decoration: none;
    border: none;
    text-align: center;
    width: 14rem;
    cursor: pointer;
    margin: 1rem;
    padding: 2rem 0;
    font-size: 4rem;
    background: $white-complete;
    color: $silver;
    border: 0.1rem solid $clouds;
    border-radius: 0.3rem;

    * {
      -o-transition: all 0 ease-out;
      transition: none;
    }

    &:focus {
      outline: 0;
    }

    .title {
      font-size: 1.6rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .selected {
    color: $wet-asphalt;
  }
}

@media (max-width: $xs-breakpoint) {
  .gender {
    button {
      padding: 2rem 0;
      font-size: 3rem;
      width: 12rem;
      margin: 0.5rem;

      .title {
        font-size: 1.4rem;
      }
    }
  }
}

.account-favorite-club {
  position: relative;
  height: 16rem;
  margin: 2rem auto;

  .input-autocomplete {
    padding: 5rem;
  }

  .real-team {
    position: relative;

    img {
      height: 12.8rem;
      width: 12.8rem;
    }

    .title {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
    }

    button {
      width: 100%;
    }
  }
}