.box {
  @include border-radius(12px);
  width: 80%;
  background: wetAsphalt; }

.stats-valuation-period {
  @include nav-list();
  // +h4-margin(1.33em)
  a:link,a:visited {
    color: $white; }
  a:hover,a:active {
    color: $sun-flower;
    font-weight: 900;
    text-decoration: underline; } }
