.content-container {
  position: relative;
  margin: 0 auto;
  padding: 0 $m-size;
  max-width: $page-width;

  &.min-container {
    max-width: 90rem;
  }
}

.with-margin {
  margin: 4rem auto;
}

.margin-side {
  margin: auto 2rem;
}

.margin-bottom {
  margin-bottom: 4rem;
}

@media (max-width: $sm-breakpoint) {
  .content-container {
    padding: 0 $s-size;
  }
}