$logo-img-width: 18rem;
$logo-width: $logo-img-width + $m-size;
$header-menus-height: 4.8rem;
$header-content-height: 9rem;
$header-content-padding: 2rem;
$header-content-border-width: 2rem;
$header-games-height: 6rem;

.header {
  z-index: 12;
  position: relative;
  background: $wet-asphalt;
  color: $clouds;
}

.header__top {
  position: relative;
  width: 100%;
  background: $midnight-blue;
  height: $header-menus-height;

  &:before {
    content: '';
    background: $wet-asphalt url('/images/header-texture.png') repeat;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0.3;
  }

  .header-logo {
    position: absolute;
    z-index: 13;

    img {
      width: $logo-img-width;
      margin-top: 2rem;
      margin-right: $m-size;
    }
  }

  .content {
    height: $header-menus-height;
    overflow: hidden;
    width: 100%;
    z-index: 13;

    .bet-button {
      display: none;
      float: right;
      background: none;
      font-size: 1.8rem;
      line-height: 1;
      padding: #{$s-size + 0.3rem};
      font-weight: bold;
      color: $clouds;

      &__alert {
        background: $green-sea;
      }

      &:hover {
        background: $wet-asphalt;
      }
    }

    .links {
      float: left;
    }

    .auth {
      float: right;

      .button {
        .mark-mobile {
          background: $alizarin;
        }

        > div {
          display: inline-block;
          font-size: 1.5rem;
          max-width: 0;
          overflow: hidden;
          -webkit-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;

          span {
            margin-left: 1rem;
          }
        }
      }

      .selected {
        color: $turquoise;
      }

      .selected-alert {
        color: $alizarin;
      }

      .selected-info {
        color: $belize-hole;
      }

      a {
        position: relative;

        svg * {
          -o-transition: all 0 ease-out;
          transition: none;
        }

        &:hover {
          .item {
            max-width: 20rem;
          }
        }
      }

      form {
        position: relative;
        display: flex;

        > * {
          margin-bottom: $m-size;
        }
      }

      .wall-news {
        color: $peter-river;
      }

      .prime-none {
        color: $sun-flower;
      }

      .prime-expiring {
        color: $orange;
      }

      .pending-message {
        margin: 0 1rem;
        display: inline;
      }
    }

    .menu {
      float: right;

      .button {
        position: relative;
      }

      .menu-dropdown {
        margin-left: -11rem;
      }
    }

    .search {
      float: left;
      margin: 1.1rem;

      input {
        position: relative;
        width: 10rem;
        box-sizing: border-box;
        border: 0;
        border-radius: 0.8rem;
        font-size: 1.4rem;
        background-color: $wet-asphalt;
        background-image: url('/images/search_icon.svg');
        background-position: 0.8rem 0.6rem;
        background-repeat: no-repeat;
        padding: 0.4rem 2rem 0.4rem 2.5rem;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.3s ease-in-out;
        color: $clouds;

        &.large,
        &:focus {
          width: 20rem;
          outline: none;
        }

        &::placeholder {
          color: $concrete;
        }
      }

      .dropdown-input {
        width: 70%;
        padding: 1rem 1.2rem;
        background-image: none;

        &:focus {
          width: 70%;
        }
      }

      .search-dropdown {
        width: auto;

        .button {
          padding: 0.5rem 0;
        }

        .card-table {
          &:hover {
            .texts {
              .name {
                color: $white;
              }
              .tag {
                color: $silver;
              }
            }
          }
        }
      }
    }
  }
}

.header__content {
  background: $midnight-blue url('/images/header-pattern.png');
  background-size: cover;
  position: relative;
  width: 100%;
  height: $header-content-height;
  padding: $header-content-padding 0;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1) inset;

  .content {
    margin-left: $logo-width;
    height: $header-content-height - 2 * $header-content-padding;
    overflow: hidden;

    .round {
      float: right;

      .info {
        float: left;
        height: 5rem;
        margin-right: 1rem;

        .number {
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1.8rem;
          font-size: 1.8rem;
          margin-bottom: 0.2rem;
        }

        .text {
          line-height: 1.4rem;
          font-size: 1.4rem;
          text-align: right;

          > div {
            font-weight: 700;
            color: $turquoise;
            float: right;
            margin-left: 0.5rem;

            &.alert {
              color: $alizarin;
            }
          } 

          a {
            color: $alizarin;
            line-height: 2rem;
            animation: blinker 0.8s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
            font-weight: 900;

            &:before {
              content: '';
              display: inline-block;
              width: 1rem;
              height: 1rem;
              -moz-border-radius: 0.5rem;
              -webkit-border-radius: 0.5rem;
              border-radius: 0.5rem;
              background-color: $alizarin;
              margin-right: 0.5rem;
            }

            &:hover {
              color: $pumpkin;
              &:before {
                background-color: $pumpkin;
              }
            }
          }
        }
      }

      .games {
        float: left;
        height: 5rem;
        width: 53.7rem;

        button {
          &:focus {
            outline: none;
          }

          &:hover {
            background: $wet-asphalt;
            transition: none;
          }

          &:disabled {
            background: none;
            color: $wet-asphalt;
            transition: none;
          }
        }

        .game {
          float: left;
          height: 5rem;
          margin-left: 0.8rem;
          font-weight: 700;

          .team {
            display: flex;
            flex-direction: row;
            font-size: 1.4rem;
            line-height: 1.8rem;

            .name {
              width: 3.8rem;
              text-align: left;
            }

            .score {
              width: 1.2rem;
            }
          }

          .status {
            height: 1.2rem;

            .line-bar {
              width: 5rem;
              padding: 0.2rem 0.4rem 0.2rem 0;
              line-height: 0.8rem;
              height: 1.2rem;
            }

            .text {
              padding-top: 0.2rem;
              line-height: 1rem;
              font-size: 1rem;
              text-transform: uppercase;
            }
          }

          .canceled {
            color: $alizarin;
          }

          .in_progress {
            color: $sun-flower;
          }

          .not_started {
            color: $concrete;
          }

          .finished {
            color: $silver;
          }

          .lose {
            font-weight: 400;
          }
        }
      }
    }
  }
}

.header__bottom {
  background: $midnight-blue;
  position: relative;
  box-shadow: 0 -1rem 1rem rgba(0, 0, 0, 0.1);

  &:before {
    content: '';
    background: $wet-asphalt url('/images/header-texture.png') repeat;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0.3;
  }

  .content {
    margin-left: $logo-width + $header-content-border-width;
    height: $header-games-height;
    padding-left: 1rem;
    position: relative;

    .public-buttons {
      float: right;
      height: 100%;

      .button {
        background: none;
        line-height: 3.6rem;
        padding: 1.2rem 2rem;
        color: $clouds;

        &:hover {
          background: $wet-asphalt;
        }
      }

      .register {
        background: #2980b966;
        font-weight: bold;

        &.selected {
          background: $wet-asphalt;
        }

        &:hover {
          background: $belize-hole;
        }
      }
    }

    .bet-button {
      float: right;
      background: none;
      line-height: 3.6rem;
      padding: 1.2rem 2rem;
      font-weight: bold;
      color: $clouds;

      &__alert {
        background: $green-sea;
      }

      &:hover {
        background: $wet-asphalt;
      }
    }

    .links {
      height: 100%;
      overflow: hidden;

      .mode {
        float: left;
        overflow: hidden;

        .mode-button {
          position: relative;
          display: flex;
          flex-direction: row;
          background: none;
          border: none;
          color: white;
          font-size: $font-size-large;
          font-weight: 300;
          line-height: $header-games-height - 2 * $s-size;
          height: $header-games-height;
          padding: $s-size;
          text-decoration: none;

          &:focus,
          &:hover {
            background: rgba(41, 128, 185, 0.3);
            outline: 0;

            &:after {
              position: absolute;
              content: '';
              display: block;
              background: $clouds;
              width: 100%;
              height: 0.6rem;
              left: 0;
              bottom: 0;
            }
          }

          img {
            height: 3.6rem;
          }

          mark {
            display: none;
            top: 0.5rem;
            left: 3.8rem;
            background: $pomegranate;
          }

          .texts {
            height: 3.6rem;
            margin-left: 0.8rem;
            text-align: left;

            .name {
              line-height: 3.6rem;

              &__team {
                line-height: 2rem;
              }
            }

            .team {
              margin-top: 0.2rem;
              line-height: 1.4rem;
              color: $turquoise;
              letter-spacing: -0.1rem;
              font-size: 1.4rem;
            }
          }

          &.alert {
            mark {
              display: block;
            }

            .texts {
              margin-left: 1.8rem;
            }
          }
        }

        .selected {
          button {
            background: rgba(41, 128, 185, 0.3);

            .name {
              font-weight: 400;
            }

            .team {
              color: $white;
            }

            &:after {
              position: absolute;
              content: '';
              display: block;
              background: $green-sea;
              width: 100%;
              height: 0.6rem;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $md-breakpoint) {
  .header__top {
    .content {
      .auth {
        width: 100%;

        .bet-button {
          display: block;
        }

        .button {
          float: right;
        }

        .menu {
          float: left;

          .menu-dropdown {
            margin-left: 0;
          }
        }

        .search {
          position: relative;
          float: left;
          margin: 0;
        }
      }
    }

    .header-logo {
      left: calc(50% - 5rem);

      img {
        width: 10rem;
        margin: 0;
        margin-top: 0.7rem;
      }
    }
  }

  .header__content {
    height: $header-content-height + 5.6rem;

    &.no-games {
      height: ($header-content-height + 5.6rem) / 2;
    }

    .content {
      margin-left: 0;
      height: ($header-content-height + 5.6rem) - 2 * $header-content-padding;

      .round {
        width: 100%;
        height: 100%;
        .info {
          width: 100%;
          height: 5.6rem;
          margin: 0;
          text-align: center;

          .number {
            float: left;
            width: calc(50% - 6rem);
            line-height: 3rem;
            font-size: 1.6rem;
          }

          .text {
            float: right;
            width: calc(50% - 6rem);
            line-height: 1.5rem;

            > div {
              float: none;
              margin-left: 0;
            }

            &.closed {
              line-height: 3rem;
              margin-right: 1.5rem;
            }

            &.right {
              text-align: center;
            }
          }
        }

        .games {
          width: 100%;
        }
      }
    }
  }

  .header__bottom {
    .content {
      margin-left: 0;
      padding-left: 0;

      .public-buttons {
        width: 100%;

        .button {
          text-align: center;
          width: 50%;
        }
      }

      .bet-button {
        display: none;
      }

      .links {
        .mode {
          width: 20%;

          .mode-button {
            width: 100%;

            img {
              height: 3rem;
              margin: 0 calc((100% - 3rem) / 2);
            }

            .texts {
              display: none;
            }

            &.alert {
              mark {
                right: 1rem;
                left: auto;
              }
            }
          }
        }
      }
    }
  }
}
