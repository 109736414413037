.editor-html {
  height: 60rem;
  position: relative;

  .for-container {
    display: flex;
    flex-direction: column;
    height: 60rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    font-size: 14px;

    .for-controlbar {
      display: flex;
      justify-content: space-between;
      padding: 0 6px;
      border-bottom: 1px solid #ddd;
      color: #555;
      user-select: none;
    }

    .for-editor {
      display: flex;
      justify-content: space-between;
      height: 100%;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 0 0 4px 4px;
      overflow: hidden;

      .for-panel {
        height: 100%;
        flex: 1;
        width: 50%;
        overflow: auto;

        .for-preview {
          border-left: 0.1rem solid #ccc;

          iframe {
            display: block;
            width: 100%;
            border: none;
            height: 59.8rem;
          }
        }
      }

      .for-editor-wrapper {
        line-height: 1.6;

        .for-editor-block {
          display: flex;
          justify-content: space-between;
          min-height: 100%;
        }

        .for-line-num {
          list-style: none;
          background: #eee;
          padding: 0.8rem 0 0%;
          min-width: 3rem;
          text-align: center;

          &.hidden {
            display: none;
          }

          li {
            list-style: none;
          }

        }

        .for-editor-content {
          flex: 1;
          height: 100%;
          position: relative;
          margin: 0 1rem;

          pre {
            padding: 0.8rem 0;
            display: block;
            white-space: pre-wrap;
            word-wrap: break-word;
            visibility: hidden;
            margin: 0;
            font-family: inherit;
            min-height: 59.8rem;
          }
        }
      }
    }

    textarea {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 8px 0;
      font-family: inherit;
      display: block;
      height: 100%;
      width: 100%;
      overflow: hidden;
      resize: none;
      border: none;
      outline: none;
      font-size: inherit;
      color: rgba(0, 0, 0, 0.65);
      background: none;
      line-height: inherit;
    }
  }

  .for-markdown-preview {
    height: 100%;
    line-height: 2;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;

    p,
    blockquote,
    ul,
    ol,
    dl,
    pre {
      margin-top: 0;
      margin-bottom: .6em;
    }

    h1,
    h2 {
      border-bottom: 1px solid #e2e2e2;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0 0 .6em;
      font-weight: 600;

      text-indent: 0;

      &:target {
        padding-top: 4.5rem;
      }
    }

    a {
      color: #0366d6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }


    ul,
    ol {
      padding: .2em .8em;

      >li {
        line-height: 2;
        padding-left: .2em;
        margin-left: .2em;
        list-style-type: disc;

        >p {
          text-indent: 0;
        }

        >ul {
          li {
            list-style-type: circle;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    >ul,
    ol {
      padding: 0 20px;
    }

    ol>li {
      list-style-type: decimal;
    }

    blockquote {
      margin: 0;
      margin-bottom: .6em;
      padding: 0 1em;
      color: #6a737d;
      border-left: 0.25em solid #dfe2e5;

      p {
        text-indent: 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    pre {
      padding: .6em;
      overflow: auto;
      line-height: 1.6;
      background-color: #f0f0f0;
      border-radius: 3px;

      code {
        padding: 0;
        margin: 0;
        font-size: 100%;
        background: transparent;
      }
    }

    code {
      padding: 0.2em 0.4em;
      margin: 0;
      background-color: #f0f0f0;
      border-radius: 3px;
    }

    hr {
      margin-bottom: .6em;
      height: 1px;
      background: #dadada;
      border: none;
    }

    table {
      width: 100%;
      border: 1px solid #ddd;
      margin-bottom: .6em;
      border-collapse: collapse;
      text-align: left;

      th,
      td {
        padding: .1em .4em;
        border: 1px solid #ddd;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      margin-bottom: .6em;
    }
  }
}