.nav-tabs {
  position: relative;
  text-align:center;
  list-style: none;
  border: 0;
  padding: 0;
  margin: 1rem auto;
  overflow-x: auto;

  .item {
    display:inline-block;
    margin-left: 0;
    margin-bottom: 2rem;
    line-height: 2rem;
    padding: 1rem 1rem;
    background: none;
    border: 0;
    border-bottom: 3px solid #34495e13;
    border-radius: 0;
    color: $concrete;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;

    &.active, &:hover {
      color: $wet-asphalt;
      border-color: $wet-asphalt;
    }
  }
}