//Statistics

%position-background {
  @include border-radius(10px);
  background-color: black;
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0.5rem;
  margin: 0 1rem 0 0.5rem;
  color: $white;
 }  // margin-right: 1rem

.neutral-position {
  @extend %position-background;
  background-color: $asbestos; }

.increase-position {
  @extend %position-background;
  background-color: $nephritis; }

.decrease-position {
  @extend %position-background;
  background-color: $alizarin; }

.best-position {
  @extend %position-background;
  background-color: $sun-flower; }

.stats-info {
  margin-bottom: 1rem;
  vertical-align: middle;
  height: 10rem; }

.stats-value {
  @include go-to-left(60%); }

.period {
  @include go-to-left(40%);
  text-align: right; }

.chart {
  top: 50%;
  height: 30rem; }

