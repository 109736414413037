.teams-details {

  .team-overview {

    .team-bets {
      padding: 0;
    }

    .title {
      margin: 3rem auto;
      width: 24rem;
      overflow: hidden;
      display: flex;
      flex-direction: row;

      &.md {
        width: 28rem;
      }

      &.lg {
        width: 32rem;
      }

      .level {
        display: flex;
        color: $pomegranate;
        font-size: 9rem;
        line-height: 9rem;
        font-weight: 900;
        letter-spacing: -1rem;
        padding-right: 2rem;
      }

      .texts {
        .name {
          color: $wet-asphalt;
          padding-top: 0.5rem;
          font-size: 4.6rem;
          line-height: 4.6rem;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: -0.2rem;
        }

        .reputation {
          text-align: left;
          color: $pomegranate;
          padding-top: 0rem;
          font-size: 2.8rem;
          line-height: 2.8rem;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: -0.1rem;
        }
      }
    }

    .players {
      margin-top: 1rem;
      margin-bottom: 4rem;

      .buttons {
        text-align: center;

        .button {
          margin-right: 1rem;
          margin-bottom: 2rem;
        }
      }

      .field {
        margin-top: 3rem;
        margin-bottom: 1rem;
        overflow: auto;
        max-width: 51rem;
        margin: auto;

        .formation {
          float: left;
          width: 100%;
          position: relative;
          padding-top: 134%;

          .player {
            display: inline-block;
            text-align: center;
            position: relative;
            z-index: 1;
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            border: none;
            background: none;
            color: $nephritis;
            padding: 0;
          }
        }
      }
    }

    .roles {
      position: relative;
      padding: 3rem 0;
      max-width: 90rem;
      margin: auto;
    }

    .badges {
      position: relative;
      padding: 4rem 1rem;
      padding-bottom: 2rem;
      background: $black url('/images/team_badges.jpg') no-repeat;
      background-position: center;
      background-size: cover;
      color: $clouds;

      .title-line {
        color: $clouds;
        text-align: center;

        &:after {
          border-color: $clouds;
          width: 6rem;
          margin: 0 calc(50% - 3rem);
          margin-top: 0.6rem;
        }
      }

      .infos {
        position: relative;
        margin: auto;
        height: 3rem;
        line-height: 3rem;
        font-size: 1.7rem;
        text-align: center;

        >span {
          margin: 0 0.7rem;
          text-align: center;

          &.text {
            color: $asbestos;
          }
        }
      }

      .empty {
        text-align: center;
        padding-top: 5rem;

        svg {
          font-size: 14rem;
          opacity: 0.1;
        }

        >div {
          opacity: 0.3;
          margin-top: 3rem;
          font-size: 1.8rem;
          text-align: center;
          font-weight: bold;
        }
      }

      .carousel {
        position: relative;
        height: 34rem;
      }

      .champion {
        margin: 2rem 0;
        width: 100%;
        color: $clouds;

        .image {
          height: 18rem;
          text-align: center;

          img {
            height: 100%;
            width: auto;
          }
        }

        .name {
          margin-top: 2rem;
          font-size: 1.8rem;
          text-align: center;
          font-weight: bold;
        }
      }
    }

    .statistics {
      position: relative;
      padding: 3rem 0;
      background: url('/images/bg_noise.jpg') repeat;
      margin: auto;

      table {
        margin-bottom: 2rem;
      }
    }
  }

  .team-portal {
    iframe {
      display: block;
      height: 90rem;
      width: 100%;
      border: none;
    }
  }

  .team-players {
    .player {
      margin: 1rem auto;

      .picture {
        position: relative;
        margin: 0;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          margin: 0;
          vertical-align: middle;
        }

        .stats {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, .6);
        }

        &.hovered {
          img {
            transform: scale(1.5);
          }

          .stats {
            display: block;
          }
        }
      }

      .info {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-bottom: 1rem;

        .level {
          display: flex;
          color: $pomegranate;
          font-size: 3rem;
          line-height: 3rem;
          font-weight: 900;
          letter-spacing: -1rem;
          padding-right: 1rem;
        }

        .texts {
          width: 100%;
          text-align: left;

          .name {
            color: $wet-asphalt;
            padding-top: 0.5rem;
            font-size: 1.8rem;
            line-height: 1.8rem;
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: -0.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .label {
            color: $pomegranate;
            padding-top: 0rem;
            font-size: 1.2rem;
            line-height: 1.2rem;
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: -0.1rem;
          }
        }
      }
    }
  }

  .team-games {
    .game-container {
      margin: 1rem 0;
    }

    .button {
      width: 100%;
    }
  }

  .team-achievements {
    .titles-empty {
      margin: 1rem 0 3rem 0;
      text-align: center;
    }

    .titles {
      background: $black url('/images/team_badges.jpg') no-repeat;
      background-position: center;
      background-size: cover;

      >div {
        position: relative;
        overflow: auto;
      }

      .title {
        position: relative;
        height: 40rem;
        background: url('/images/achievements_team.png') no-repeat;
        background-size: auto 100%;
        background-position: center center;
        overflow: hidden;
        margin-bottom: 2rem;

        .item {
          margin: auto;
          width: 20rem;
          text-align: center;

          img {
            width: 16rem;
            height: auto;
            margin-top: 9rem;
          }

          .name {
            text-align: center;
            color: $white;
            margin-top: 9rem;
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: bold;
          }
        }

        &:hover {
          .item {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .friendly-received {
    margin-bottom: 4rem;
  }

  .friendly-search {
    input {
      margin-bottom: 2rem;
    }
  }

  .search-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;

    .pagination-header {
      margin-top: 1.6rem;
    }
  }

  .market-management {
    .content {
      margin-bottom: 3rem;
    }
  }

  .roles-management {
    svg {
      font-size: 4rem;
      margin-bottom: 0.4rem;
      color: $belize-hole;
      -o-transition: all 0 ease-out;
      transition: none;
    }

    h4 {
      color: $wet-asphalt;
      text-align: center;
      margin-bottom: 2.4rem;
    }

    .content {
      margin: 0.5rem 0;
      height: 13rem;
      overflow: hidden;

      .filter-select,
      input {
        margin: auto;
        margin-top: 2rem;
      }

      .filter-select {
        width: 20rem;
      }
    }

    .button {
      margin: 0.3rem;
    }
  }

  .escalation-formation {

    .header-manager {
      width: 100%;
      overflow: auto;
      margin-bottom: 1rem;

      .currency {
        margin-top: 0.5rem;
        float: left;
        display: flex;

        .value {
          font-size: 1.8rem;
          line-height: 3rem;
          font-weight: bold;
          text-align: center;

          border-radius: 0.3rem;
          background-color: $belize-hole;
          color: $white;
          width: 12rem;

          &.negative {
            background-color: $alizarin;
          }
        }
      }

      .tactic {
        margin-top: 0.5rem;
        float: left;
        display: flex;
        margin-right: 1.5rem;
      }

      .label {
        line-height: 3rem;
        margin-right: 1rem;
        font-weight: bold;
      }

      .buttons {
        float: right;

        .button {
          margin-left: 1rem;
        }
      }
    }

    .players-list {
      .players-list-header {
        overflow: auto;
        margin-top: 1rem;
      }

      .filter {
        float: left;
        display: flex;
        flex-direction: row;

        .filter-select {
          width: 18rem;
          background: $wet-asphalt;
        }

        .filter-input {
          margin-left: 1rem;
        }
      }

      .paginate {
        float: right;
      }
    }

    .formation {
      float: left;
      width: 100%;
      position: relative;
      padding-top: 134%;
    }
  }

  .portal-management {
    text-align: center;

    button {
      margin-top: 2rem;
    }
  }
}

@media (max-width: $md-breakpoint) {
  .teams-details {
    .team-overview {
      .players {
        .title {
          margin: auto;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .teams-details {
    .search-header {
      input {
        width: 54%;
      }
    }

    .team-overview {
      .players {
        margin-bottom: 2rem;

        .buttons {
          .button {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }

        .field {
          margin: 0 auto;

          .formation {
            .player {
              width: 5rem;
              height: 5rem;
            }
          }
        }
      }
    }

    .escalation-formation {
      .header-manager {
        .currency {
          float: none;
          margin-bottom: 2rem;
        }

        .buttons {
          text-align: center;
          float: none;
          margin-bottom: 1rem;
        }
      }

      .players-list {
        .filter {
          margin-bottom: 2rem;
        }
      }
    }
  }
}