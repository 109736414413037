.paginate {
  position: relative;
  text-align: right;

  .pagination {
    padding: 0;
    margin: 0.2rem 0;

    li {
      display: inline-block;
      background: none;
      color: $wet-asphalt;
      font-weight: 900;
      padding: 0.1rem 0.7rem;
      margin: 0 0.1rem;
      border-radius: 0.3rem;
      font-size: 1.6rem;

      -o-transition: all 0 ease-out;
      transition: none;

      &:hover {
        background: $wet-asphalt;
        color: $white;
      }

      &.next,
      &.previous {
        font-weight: bold;
        background: $wet-asphalt;
        color: $white;
        padding: 0.1rem 1rem;
        border-radius: 0.3rem;

        &:hover {
          background: $pomegranate;
        }

        &.disabled {
          display: none;
        }
      }

      a {
        cursor: pointer;
        outline: 0;
        border: none;
      }

      &.break {
        a {
          cursor: default;
        }
      }

      &.active {
        background: $pomegranate;
        color: $white;

        a {
          cursor: default;
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .paginate {  
    .pagination {  
      li {
        padding: 0.1rem 0.4rem;
        font-size: 1.5rem;
        margin: 0;
  
        &.next,
        &.previous {
          padding: 0.1rem 0.5rem;
          font-size: 1.4rem;
          line-height: 2.5rem;
        }
      }
    }
  }
}
