.categories-icons {
  max-width: 90rem;
  margin: 1rem auto;

  .item {
    position: relative;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.15);
    background: $white;
    margin: 1rem 0;
    width: 100%;
    padding: 3rem 1rem;
    text-align: center;
    border: 0.3rem solid rgba(0, 0, 0, 0);
    border-radius: 0.3rem;

    mark {
      right: 0.5rem;
      top: 0.5rem;
      width: 3rem;
      height: 3rem;
      font-size: 1.4rem;
      line-height: 2.8rem;
      background: $belize-hole;
    }

    .title {
      color: $wet-asphalt;
      font-weight: bold;
      font-size: 1.6rem;
    }

    .description {
      height: 4.4rem;
      color: $wet-asphalt;
      font-size: 1.4rem;
      line-height: 2.2rem;
      overflow: hidden;
    }

    svg {
      font-size: 4rem;
      margin-bottom: 1rem;
      color: $belize-hole;
      -o-transition: all 0 ease-out;
      transition: none;
    }

    &:hover {
      border-color: $wet-asphalt;

      svg {
        color: $wet-asphalt;
      }
    }
  }
}