.transactions-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  h3 {
    span {
      font-size: 2.5rem;
      background: $green-sea;
      color: $white;
      border-radius: 0.3rem;
      padding: 0.6rem 2rem;
    }
  }
}