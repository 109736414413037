.game-bets {
  .game-bets-card {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
    overflow: hidden;

    .bets-header {
      background: $wet-asphalt;
      color: $white;
      text-transform: uppercase;
      line-height: 3rem;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 0 1rem;
      text-align: center;
    }

    .bets-info {
      background: $white;
      color: $wet-asphalt;
      display: flex;
      border-bottom: 0.2rem solid $wet-asphalt;

      .player {
        width: 34rem;
      }

      .result {
        width: calc(100% - 68rem);
        text-align: center;
        font-weight: 900;
        line-height: 5rem;
        font-size: 3.4rem;

        &.in-progress {
          color: $belize-hole;
        }

        .text {
          display: flex;
          justify-content: center;

          div {
            width: 2rem;
            opacity: 0.7;
          }

          div:nth-child(2) {
            width: 1.5rem;
          }

          .win {
            opacity: 1;
          }
        }

        .goals {
          width: 3.4rem;
          overflow: hidden;
          color: $black;
          font-size: 1.4rem;
          position: absolute;

          &.home {
            left: calc(50% - 7rem);

            div {
              float: right;
            }

            .icon {
              order: 2;
            }

            .value {
              order: 1;
              padding-right: 0.5rem;
            }
          }

          &.away {
            left: calc(50% + 3.6rem);

            div {
              float: left;
            }

            .icon {
              order: 1;
            }

            .value {
              order: 2;
              padding-left: 0.5rem;
            }
          }

          .value {
            font-size: 1.1rem;
          }
        }

        &.big {
          .text {
            div:nth-child(odd) {
              width: 3rem;
              font-size: 2.4rem;
            }
          }

          .goals {
            &.home {
              left: calc(50% - 7.4rem);
            }

            &.away {
              left: calc(50% + 4rem);
            }
          }
        }
      }
    }

    .mobile-players {
      display: none;
      background: $white;
      justify-content: space-between;
      padding: 0 0.6rem;
      border-bottom: 0.2rem solid #e3e5e6;

      .player-name {
        display: flex;
        color: $wet-asphalt;
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: $wet-asphalt;
          font-weight: bold;

          &:hover {
            opacity: 0.7;
          }
        }

        .goals {
          position: relative;
          overflow: hidden;
          color: $black;
          font-size: 1.4rem;
          line-height: 2.4rem;
          display: flex;

          &.home {
            padding-left: 0.5rem;
          }

          &.away {
            padding-right: 0.5rem;

            .icon {
              order: 2;
            }

            .value {
              order: 1;
            }
          }

          .value {
            font-size: 1.1rem;
            font-weight: bold;
            padding: 0 0.3rem;
          }
        }
      }
    }

    .select-players {
      display: flex;
      background: $clouds;
      padding: 0 0.6rem;
      border-bottom: 0.2rem solid #e3e5e6;
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: bold;
      padding-top: 0.2rem;

      .result {
        width: 6rem;
        text-align: center;
      }

      .player-name {
        width: calc(50% - 3rem);

        &.away {
          text-align: right;
        }

        select {
          border: 0;
          background-color: transparent;
          font-weight: bold;
          font-size: 1.4rem;
          color: $wet-asphalt;
          appearance: none;
          text-align-last: left;
          direction: rtl;

          option {
            direction: ltr;
          }

          &.away {
            text-align-last: right;
            direction: ltr;

            option {
              direction: rtl;
            }
          }

          &:focus {
            outline-width: 0;
          }
        }
      }
    }

    .bets-details {
      background: $white;
      color: $wet-asphalt;

      a {
        color: $wet-asphalt;
      }

      .game-result {
        border-bottom: 0.2rem solid #e3e5e6;
        display: flex;
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: bold;
        padding-top: 0.2rem;

        .bet {
          position: relative;
          width: 6rem;
          text-align: center;

          .score-indicator,
          .score-indicator-bonus {
            position: absolute;
            width: 0.6rem;
            height: 1.8rem;
            left: 0.5rem;
            top: 0.2rem;

            &.away {
              left: auto;
              right: 0.5rem;
            }
          }

          .score-indicator-bonus {
            left: auto;
            right: 0.5rem;

            &.away {
              left: 0.5rem;
              right: auto;
            }
          }

          .goals {
            width: 3.4rem;
            overflow: hidden;
            color: $black;
            top: 0;
            font-size: 1.4rem;
            position: absolute;

            &.home {
              right: -2.4rem;

              div {
                float: left;
              }

              .icon {
                order: 1;
              }

              .value {
                order: 2;
                padding-left: 0.5rem;
              }
            }

            &.away {
              left: -2.4rem;

              div {
                float: right;
              }

              .icon {
                order: 2;
              }

              .value {
                order: 1;
                padding-right: 0.5rem;
              }
            }

            .value {
              font-size: 1.1rem;
            }
          }
        }

        .name {
          width: calc(50% - 6rem);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          opacity: 0.7;

          &.home {
            text-align: right;
          }
        }

        .result {
          width: 3rem;
          text-align: center;

          &.in-progress {
            color: $peter-river;
          }
        }
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .game-bets {
    .game-bets-card {
      .bets-info {
        .player {
          position: relative;
          width: 14rem;

          .card-squad {
            .texts {
              display: none;
            }
          }

          &:after {
            position: absolute;
            content: '';
            display: block;
            top: 0;
          }

          &.home {
            &:after {
              right: 0;
              border-bottom: 5rem solid transparent;
              border-right: 3rem solid $white;
            }
          }

          &.away {
            &:after {
              left: 0;
              border-top: 5rem solid transparent;
              border-left: 3rem solid $white;
            }

            .real-team-card {
              .badge {
                img {
                  left: 16%;
                }
              }
            }
          }
        }

        .result {
          width: calc(100% - 28rem);

          .goals {
            display: none;
          }
        }
      }

      .mobile-players {
        display: flex;
      }

      .bets-details {
        .game-result {
          .bet {
            .goals {
              display: none;
            }
          }
        }
      }
    }
  }
}