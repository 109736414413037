.campaign-team {
  margin: 4rem 0;

  .header-campaign-team {
    position: relative;
    display: flex;
    height: 20rem;

    .mask {
      position: absolute;
      clip-path: polygon(calc(100% - 4rem) 0%, 100% 50%, calc(100% - 4rem) 100%, 0% 100%, 4rem 50%, 0% 0%);
      width: 50%;
      height: 20rem;
      z-index: 1;
      top: 0;

      .texture {
        background: url('/images/campaign/logo-texture.jpg') 100%;
        width: 100%;
        height: 100%;
        opacity: 0.25;
      }
    }

    .team {
      width: 50%;
    }

    .logo {
      height: 100%;
      position: relative;
      padding: 2rem 1.5rem 2rem 4.5rem;
      width: 100%;
      text-align: center;

      img {
        position: relative;
        z-index: 2;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
      }
    }

    .info {
      height: 100%;
      position: relative;
      width: 100%;
      z-index: 2;
      padding: 1.4rem 2rem 1.4rem 3rem;
      overflow: hidden;

      .bets-none {
        padding: 3.4rem 0;
      }

      .title {
        font-size: 1.8rem;
        text-align: center;
        font-weight: 900;
        text-transform: uppercase;
      }

      .icon {
        text-align: center;
        font-size: 7rem;
      }

      .text {
        font-size: 1.4rem;
        text-align: center;
        font-weight: bold;
      }

      &.victory {
        .title {
          color: $belize-hole;
        }
      }

      &.loss {
        .title {
          color: $pomegranate;
        }
      }

      &.paused {
        text-align: center;

        button {
          margin-top: 6.1rem;

          &:hover {
            color: $concrete;
          }
        }
      }

      &.active {
        .data {
          height: 8rem;
          text-align: center;

          .position {
            color: $pomegranate;
            font-weight: bold;
            font-size: 1.3rem;
            line-height: 1.3rem;
            text-transform: uppercase;
            width: 100%;
          }

          .score {
            margin-top: 4.6rem;
            position: relative;
            font-size: 5rem;
            line-height: 5.6rem;
            font-weight: 900;

            .target {
              font-weight: bold;
              font-size: 2rem;
              letter-spacing: -0.1rem;
            }

            .pt {
              font-weight: bold;
              font-size: 1.8rem;
              letter-spacing: -0.1rem;
            }

            .position {
              position: absolute;
              bottom: 0;
            }
          }

          .playoff-phase {
            margin-top: 6rem;
            font-size: 2rem;
            line-height: 2.8rem;
            font-weight: 900;
          }
        }

        .classificatory {
          width: 100%;
          padding: 1rem 2rem;

          .team-container {
            display: flex;
            width: 100%;

            .position {
              width: 2rem;
              text-align: center;
              font-size: 1.4rem;
            }

            .name {
              font-size: 1.3rem;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.selected {
              color: $pomegranate;
            }
          }
        }
      }
    }
  }
}

.campaign-invite-search {
  margin: 4rem 0;
}

.campaign-details-games {
  padding: 3rem 0;
  width: 100%;
  position: relative;
  background: #34495e;
  background: url(/images/player_games.jpg) center;
  background-size: cover;
  height: 32rem;

  .container {
    position: relative;
    height: 20rem;
    width: 100%;
    flex: 0 0 auto;
    color: $white;
    font-weight: bold;

    .mask {
      position: absolute;
      clip-path: polygon(calc(100% - 4rem) 0%, 100% 50%, calc(100% - 4rem) 100%, 0% 100%, 4rem 50%, 0% 0%);
      width: calc(100% + 2.5rem);
      height: 20rem;
      z-index: 1;
      left: -4rem;
      top: 0;
      background-color: rgba(218, 218, 218, 0.9);

      &.victory {
        background-color: rgba(3, 79, 60, 0.9);
      }

      &.draw {
        background-color: rgba(10, 64, 103, 0.9);
      }

      &.loss {
        background-color: rgba(208, 12, 12, 0.9);
      }

      &.current {
        background-color: rgba(0, 0, 0, 0.9);
      }

      .texture {
        background: url('/images/campaign/logo-texture.jpg') 100%;
        width: 100%;
        height: 100%;
        opacity: 0.25;
      }
    }

    .presentation {
      position: relative;
      padding: 2rem 4.75rem 2rem 1.25rem;
      z-index: 2;
      text-align: center;

      img {
        height: 16rem;
        width: auto;
      }
    }

    .info {
      position: relative;
      padding: 1rem 6rem 1rem 0rem;
      z-index: 2;

      .title {
        color: $clouds;
        padding-left: 1rem;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }

      .top {
        height: 4rem;
        overflow: hidden;
      }

      .team {
        height: 4rem;
        display: table;
        line-height: 1.6rem;

        .logo {
          position: absolute;
          width: 4rem;
          left: 1.5rem;

          img {
            width: 100%;
          }
        }

        .name {
          padding-left: 6rem;
          position: relative;
          display: table-cell;
          vertical-align: middle;
        }
      }

      .result {
        margin: 0.7rem 0 0.7rem 2rem;
        text-shadow: -0.3rem 0.3rem rgba($black, 0.2);
        line-height: 3rem;
        height: 3rem;

        .text {
          float: left;
          font-size: 2rem;
        }

        .score {
          float: right;
          font-size: 3rem;
        }
      }

      .bottom {
        .user {
          font-size: 1.2rem;
          line-height: 1.6rem;
          height: 1.6rem;
          position: relative;
          color: $clouds;

          .name {
            float: left;
            margin-left: 2rem;
          }

          .score {
            float: right;
          }
        }
      }
    }
  }
}

.campaign-details-current {
  margin: 3rem 0 3rem 2rem;
  overflow: hidden;

  .header-game {
    background: $midnight-blue;
    line-height: 3rem;
    font-weight: bold;
    color: $clouds;
    font-size: 1.4rem;
    padding: 0 2rem;
  }

  .team {
    position: relative;
    background: $clouds;
    padding: 2rem;
    height: 16rem;
    width: 100%;
    display: table;
    background: url('/images/campaign/logo-texture.jpg') 100%;

    .logo {
      display: table-cell;
      height: 14rem;
      padding: 0 2rem;
      width: 20rem;

      img {
        height: 100%;
        width: auto;
      }
    }

    .name {
      padding-left: 2rem;
      display: table-cell;
      vertical-align: middle;
      font-weight: bold;
      font-size: 4rem;
    }

    .score-total {
      position: absolute;
      right: 0;
      bottom: 0;
      padding-right: 1rem;
      padding-bottom: 0.4rem;
      font-size: 1.3rem;
      font-weight: bold;
    }
  }

  .result {
    background: $wet-asphalt;
    display: flex;
    font-weight: bold;
    height: 4rem;

    &:nth-child(2n) {
      background: $midnight-blue;
    }

    .final {
      width: 100%;
      text-align: center;
      color: $clouds;
      line-height: 4rem;
      font-weight: bold;
      text-transform: uppercase;
      opacity: 0.9;

      &.old {
        color: $concrete;
        text-decoration: line-through;
      }
    }

    .name {
      color: $silver;
      font-size: 1.4rem;
      line-height: 4rem;
      padding: 0 0 0 2rem;
      width: 8rem;
    }

    .bar {
      width: calc(100% - 16rem);
      margin: 1.4rem 0;
      height: 1.2rem;

      .progress {
        height: 1.2rem;
        margin: auto;
      }
    }

    .value {
      color: $clouds;
      width: 8rem;
      text-align: center;
      line-height: 4rem;
    }
  }
}

@media (max-width: $md-breakpoint) {
  .campaign-details-current {
    margin: 3rem 0 3rem 0rem;

    .team {
      padding: 2rem 0.5rem 3rem 0.5rem;
      display: block;
      height: auto;

      .logo {
        display: block;
        padding: 0;
        width: 100%;
        text-align: center;
      }

      .name {
        display: block;
        text-align: center;
        font-size: 3rem;
        padding: 0;
      }

      .score-total {
        width: 100%;
        text-align: center;
        padding-right: 0;
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .campaign-invite-search {
    margin: 0 0 4rem 0;
  }
}

@media (max-width: $xs-breakpoint) {
  .campaign-team {

    .header-campaign-team {
      .team:nth-child(2) {
        position: absolute;
        left: calc(50% - 4rem);
        width: calc(50% + 4rem);

        .mask {
          width: 100%;
        }
      }

      .info {
        &.paused {
          button {
            margin-top: 6.5rem;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}