.multiple-carrossel {
  position: relative;
  height: 100%;
  width: 100%;

  .button-action {
    float: left;
    width: 2rem;
    overflow: hidden;
    -o-transition: all 0 ease-out;
    transition: none;
    text-align: center;
    height: 100%;

    button {
      background: none;
      color: $concrete;
      height: 100%;
      width: 100%;
      padding: 0;
    }
  }

  .indicator {
    float: left;
    width: 100%;
    height: 4rem;
    box-sizing: border-box;
    text-align: center;
    font-size: 1.4rem;
    line-height: 4rem;
    margin-bottom: 1rem;

    * {
      -o-transition: all 0 ease-out;
      transition: none;
    }

    span {
      margin: 0 0.3rem;
      cursor: pointer;
      color: $white;

      path {
        stroke: $wet-asphalt;
        stroke-width: 80;
      }

      &.active {
        color: $wet-asphalt;

        path {
          stroke: $concrete;
        }
      }
    }
  }

  .items {
    float: left;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -o-transition: all 0 ease-out;
    transition: none;

    &.btn_actived {
      width: calc(100% - 4rem);
    }

    .inner {
      position: absolute;
      height: 100%;

      >.item {
        transition: 500ms ease-in-out left;
      }
    }

    .item {
      position: relative;
      float: left;
      height: 100%;
    }
  }
}