.administration-round {
  text-align: center;

  button {
    margin-top: 2rem;
  }

  .round-game {
    padding: 2rem 1rem;
    display: block;

    .teams {
      display: flex;
    }

    .team {
      width: 50%;
      height: 11rem;
      transform: none;

      .autocomplete {
        margin-top: 3rem;
      }
    }

    .competition {
      width: 100%;
      height: 3rem;
      margin-top: 1rem;

      .name {
        text-align: center;
        color: $pomegranate;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: -0.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 3rem;
      }
    }

    .date {
      margin-top: 1rem;
      padding: 0 0.5rem;

      input {
        width: 100%;
        outline: 0;
        border-width: 0 0 0.2rem;
        border-color: $wet-asphalt;
        background: none;
        font-size: 100%;
        padding: 0.2rem;
        height: auto;
        text-align: center;

        &::placeholder {
          color: $wet-asphalt;
        }
      }
    }

    .team,
    .competition {
      padding: 0 0.5rem;

      .autocomplete {
        position: relative;
        display: flex;
        overflow: hidden;

        div {
          width: 100%;

          input {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}
