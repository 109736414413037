.text-input {
  border: 1px solid #cacccd;
  height: 4.4rem;
  font-size: $font-size-large;
  font-weight: 300;
  padding: $s-size;
}

.select {
  @extend .text-input;
}

.textarea {
  @extend .text-input;
  height: 10rem;
}

.filter-select {
  overflow: hidden;
  border-radius: 0.3rem;
  background-color: $belize-hole;
  width: 12rem;

  select {
    cursor: pointer;
    font-weight: bold;
    background: transparent;
    border: none;
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 0.2rem;
    padding-left: 0.7rem;
    width: 100%;
    color: $white-complete;
    text-align: center;
    text-align-last: center;

    option {
      color: $black;
    }
  }

  &.center {
    margin: auto;
  }
}

.filter-input {
  outline: 0;
  border-width: 0 0 0.2rem;
  border-color: $wet-asphalt;
  background: none;

  &:focus {
    border-color: $pomegranate;
  }
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: $white-complete;
  }

  // Box hover
  &:hover + label:before {
    background: $wet-asphalt;
  }
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: $wet-asphalt;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: $peter-river;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: $white-complete;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 $white-complete,
      4px 0 0 $white-complete,
      4px -2px 0 $white-complete,
      4px -4px 0 $white-complete,
      4px -6px 0 $white-complete,
      4px -8px 0 $white-complete;
    transform: rotate(45deg);
  }
}
