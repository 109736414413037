.badge-list {
  .items {
    margin-top: 2rem;
  }

  .item {
    padding: 2rem;
    position: relative;
    background: $white;
    margin-bottom: 2rem;
    border-radius: 0.3rem;

    img {
      opacity: 0.2;
      width: 100%;
      height: auto;

      &.has {
        opacity: 1;
      }

      &.player {
        border-radius: 50%;
        border: $white solid 0.3rem;
      }
    }

    .lock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('/images/collectibles/lock.svg');
      background-position: center 40%;
      background-size: 50%;
      background-repeat: no-repeat;
      opacity: 0.3;

      &.winner {
        background-image: url('/images/campaign/winner.svg');
      }
    }

    mark {
      display: none;
      background: none;
      height: 3rem;
      width: 3rem;
      border-radius: 3rem;
      line-height: 2.8rem;
      font-size: 1.2rem;
      right: 1rem;
      top: 1rem;
    }

    mark {
      display: none;
      background: none;
      height: 3rem;
      width: 3rem;
      border-radius: 3rem;
      line-height: 2.8rem;
      font-size: 1.2rem;
      right: 1rem;
      top: 1rem;
    }

    .icon {
      position: absolute;
      height: 1.6rem;
      width: 2.2rem;
      left: -0.3rem;
      top: 1.5rem;
      z-index: 1;
      opacity: 0.9;
    }

    .tag {
      position: absolute;
      font-weight: 700;
      text-align: center;
      background: none;
      line-height: 2.8rem;
      font-size: 1.4rem;
      left: -1rem;
      top: 1rem;
      padding: 0 1rem;
      color: $clouds;
      border-radius: 0.2rem;

      &.with-icon {
        padding-left: 3.5rem;
      }
    }

    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-radius: 0.3rem;
      overflow: hidden;
    }

    .info {
      display: table;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 1rem;

      .name {
        display: table-cell;
        vertical-align: middle;
        font-weight: 500;
        background: rgba(255, 255, 255, 0.9);
        color: $wet-asphalt;
        border-radius: 0.2rem;
        padding: 0.2rem;
        transition: none;
      }
    }

    &.hovered {
      img {
        opacity: 1;
      }

      .lock {
        display: none;
      }

      mark {
        display: block;
      }

      .tag {
        box-shadow: rgba(0, 0, 0, 0.5) 0.4rem 0px 2rem;
      }

      .overlay {
        box-shadow: none;
      }

      .info {
        display: table;
        background: rgba(0, 0, 0, 0.3);

        .name {
          background: none;
          color: $white;
        }
      }
    }
  }
}