.game-card {
  .mode-header {
    background: $pomegranate;
    text-transform: uppercase;
    color: $clouds;
    line-height: 3rem;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0 1rem;
  }

  .game {
    display: flex;
    flex-direction: row;

    >* {
      width: calc(50% - 6rem);
    }

    .team {
      .logo {
        position: relative;
        height: 16rem;
        width: 100%;
        text-align: center;
        padding: 2rem 0.5rem;
        background: $clouds;
        overflow: hidden;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .name {
        background: $wet-asphalt;
        color: $clouds;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.6rem;
        line-height: 4rem;
        padding: 0 0.5rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background: $midnight-blue;
          color: $silver;
        }
      }
    }

    .score {
      width: 12rem;

      .top {
        background: $midnight-blue;
        text-align: center;
        text-transform: uppercase;
        color: $clouds;
        line-height: 5rem;
        font-weight: 900;
        padding: 0 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .middle {
        width: 100%;
        display: flex;
        flex-shrink: 0;
        background: $white;
        line-height: 11rem;

        .value {
          flex-grow: 2;
          color: $silver;
          font-weight: 900;
          font-size: 4.4rem;


          &__right {
            text-align: left;
          }

          &__left {
            text-align: right;
          }
        }

        .winner {
          color: $pomegranate;
        }

        .info {
          color: $midnight-blue;
        }

        .divisor {
          flex-grow: 1;
          color: $midnight-blue;
          font-weight: 900;
          font-size: 3.4rem;
          text-align: center;
        }

        &:hover {
          background: $midnight-blue;

          .value,
          .winner,
          .divisor {
            color: $clouds;
          }
        }
      }

      .bottom {
        background: $midnight-blue;
        text-align: center;
        text-transform: uppercase;
        color: $clouds;
        line-height: 4rem;
        font-size: 1.2rem;
        font-weight: 900;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .game-card {
    .game {
      .team {
        .logo {
          height: 12.5rem;
        }

        .name {
          font-size: 1.2rem;
        }
      }

      .score {
        .top {
          line-height: 4rem;
        }

        .middle {
          line-height: 8.5rem;
        }
      }
    }
  }
}


.games-calendar {
  padding: 3rem 0 1rem 0;
  position: relative;
  background: $wet-asphalt;
  background: url('/images/player_games.jpg') center;
  background-size: cover;

  .carousel {
    height: 26rem;

    &.indicator-2 {
      height: 30rem;
    }

    .item {
      padding: 0 2rem;
    }
  }

  .game-highlight {
    padding: 4rem 1rem;
  }

  .table {
    padding: 4rem 1rem;

    .game {
      position: relative;
      height: 6rem;
      width: 100%;
      background: $wet-asphalt;
      display: flex;
      flex-direction: row;

      .competition {
        width: 12rem;
        height: 100%;
        padding: 1.4rem 1.2rem;
        text-transform: uppercase;
        line-height: 1.6rem;
        font-size: 1.6rem;
        letter-spacing: -0.1rem;
        color: $clouds;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .result {
        background: $clouds;
        height: 100%;
        width: calc(100% - 12rem);

        .team {
          line-height: 6rem;
          text-align: center;
        }
      }

      &:nth-child(even) {
        background: $midnight-blue;

        .result {
          background: $silver;
        }
      }

      &:nth-child(1) {
        &:after {
          position: absolute;
          content: '';
          display: block;
          border-top: 0.3rem solid $pomegranate;
          width: 100%;
        }
      }
    }

    .bottom {
      height: 5rem;
      width: 100%;
      background: $midnight-blue;

      .content {
        margin-left: 12rem;
        width: calc(100% - 12rem);
        display: flex;
        flex-direction: row;

        .round {
          width: 10rem;
          color: $clouds;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
          white-space: nowrap;
          text-align: center;
          line-height: 5rem;
          font-size: 1.2rem;
          font-weight: 700;
        }

        .button-action {
          width: calc(50% - 5rem);

          button {
            padding: 0 1.4rem;
            width: 100%;
            height: 5rem;
            line-height: 5rem;
            font-size: 1.2rem;
            font-weight: 700;
            text-decoration: none;
            border: none;
            display: inline-block;
            background: $wet-asphalt;
            color: $clouds;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;

            &:disabled {
              color: $silver;
            }
          }

          &:nth-child(1) {
            button {
              text-align: left;
            }
          }

          &:nth-child(3) {
            button {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .one-game {
    margin-bottom: 2rem;
  }
}

@media (max-width: $sm-breakpoint) {
  .games-calendar {
    .carousel {
      &.indicator-1 {
        height: 30rem;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .games-calendar {
    .carousel {
      &.indicator-1 {
        height: 26rem;
      }
    }
  }
}

.game-classificatory {
  display: flex;
  position: relative;
  margin-bottom: 0.5rem;
  padding: 0;
  border-top: 0.4rem solid $midnight-blue;

  &:nth-child(even) {
    background: $white-complete;
  }

  &.teamhighlight {
    background: #c9e1f1;
  }

  &:hover {
    background: $silver;
  }

  .team {
    width: calc(50% - 4rem);
    transform: scale(0.8);
    height: 10.6rem;
  }

  .result {
    width: 8rem;

    a {
      display: flex;
      font-size: 3rem;
      font-weight: 900;
      color: #7f8c8d;
      letter-spacing: -0.04em;
      line-height: 10.6rem;

      .goals {
        width: calc(50% - 0.8rem);

        &:first-child {
          text-align: right;
        }

        &.winner {
          color: $black;
        }
      }

      .divisor {
        text-align: center;
        width: 1.6rem;
        line-height: 10.6rem;
        font-size: 2.2rem;
        vertical-align: middle;
      }
    }
  }
}


.game-playoff {
  text-align: center;
  font-weight: bold;

  .round {
    color: $concrete;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1rem;
  }

  .result {
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 0.8rem;

    a {
      color: $black;

      &:hover {
        color: $belize-hole;
      }
    }
  }
}


.game-list {
  margin: 0 auto;
  padding: 0 1.6rem;

  .card-simple {
    margin-bottom: 0.5rem;
    padding: 1.4rem;
  }

  .content {
    display: flex;
    position: relative;

    .result {
      text-align: center;
      width: 10rem;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: bold;
      padding: 0 0.4rem;
      color: $midnight-blue;
      -o-transition: all 0 ease-out;
      transition: none;


      .competition {
        line-height: 1.2rem;
        letter-spacing: -0.1rem;
        font-size: 1.1rem;
        color: $concrete;
        font-weight: normal;
      }

      &:hover {
        color: $belize-hole;
      }
    }

    .team-card {
      width: calc(50% - 5rem);
    }

    .team-content {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .card-table {

        .texts .name,
        .texts .tag {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .game-list {
    padding: 0;

    .card-simple {
      padding: 1rem;
    }

    .content {
      .result {
        width: 8rem;
        font-size: 1.7rem;
      }

      .team-card {
        width: calc(50% - 4rem);
      }
    }
  }
}

.game-team-highlight {
  width: 100%;
  position: relative;

  .card-simple {
    margin-bottom: 1rem;

    &:hover {
      background: $clouds;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 0.5rem;
      right: 0;
      top: 0;
      height: 100%;
    }

    &.win {
      &:before {
        background: $emerald;
      }
    }

    &.draw {
      &:before {
        background: $silver;
      }
    }

    &.lose {
      &:before {
        background: $alizarin;
      }
    }

    .team-content {
      max-width: 50rem;
      width: calc(100% - 13rem);
    }

    .round,
    .competition {
      position: absolute;
      right: 3.2rem;
      bottom: 1rem;
      font-size: 1.4rem;
      font-weight: bold;
      opacity: 0.3;
    }

    .round {
      opacity: 0.6;
      top: 1rem;
      bottom: auto;
    }

    .result {
      min-width: 13rem;
      width: calc(100% - 50rem);
      padding-right: 1rem;
      text-align: right;
      color: $asbestos;
      font-weight: 500;
      font-size: 5rem;
      line-height: 8rem;
      white-space: nowrap;
      letter-spacing: -0.2rem;

      .home {
        color: $wet-asphalt;
      }

      .away {
        color: $pomegranate;
      }

      .divisor {
        color: $asbestos;
      }
    }

    &:hover {

      .round,
      .competition {
        display: none;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .game-team-highlight {
    .content {
      .team-content {
        width: calc(100% - 8rem);
      }

      .round,
      .competition {
        right: 2.2rem;
      }

      .result {
        min-width: auto;
        width: 8rem;
        font-size: 4.4rem;
        padding-right: 0;

        &.size-1 {
          font-size: 4rem;
        }

        &.size-2 {
          font-size: 3.4rem;
        }
      }
    }
  }
}