.form {
  display: flex;
  flex-direction: column;
  >* {
    margin-bottom: $m-size;
  }
}

.form__error {
  margin: 0 0 $m-size 0;
  font-style: italic;
}
