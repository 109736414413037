.teams-list-federation {
  .box-teams {
    max-width: 784px;
    margin: auto;
  }
}

.teams-list-league {
  .paginate {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}


.team-confrontation {
  margin-bottom: 3rem;

  .title-page {
    margin-top: 3rem
  }

  input {
    margin-bottom: 2rem;
  }

  .back-button {
    position: absolute;
    right: 2rem;
    margin-top: 0.3rem;
    z-index: 1;
  }

  .information {
    margin-top: 3rem;
  }
}