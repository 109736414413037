.table-responsive {
  overflow-x: auto;

  .empty {
    width: 100%;
    padding: 1.4rem;
    font-weight: 500;

    &.dark {
      color: $silver;
    }
  }
}

table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
  line-height: 2rem;
  text-align: center;
  overflow-x: auto;
  white-space: nowrap;

  thead {
    border: 0.1rem inset rgba(0, 0, 0, 0);
    border-bottom: 0.4rem solid $midnight-blue;

    th {
      color: $midnight-blue;
      text-transform: uppercase;
      padding: 1rem 0.4rem;
      font-size: 1.4rem;
      text-align: center;
      font-weight: 900;

      &:not(.no-first):first-child {
        text-transform: none;
        font-weight: bold;
        font-size: 2rem;
        text-align: left;
      }
    }
  }

  tbody {
    tr {
      color: $concrete;
      font-weight: bold;
      border: 0.1rem inset $clouds;

      td {
        padding: 1rem;
        font-size: 1.4rem;
        text-align: center;
        max-width: 60%;
      }

      &:nth-child(odd) {
        background: $white-complete;
      }

      &:nth-child(even) {
        background: $white;
      }

      &.highlight {
        background: #c9e1f1;
      }

      &:hover {
        background: $silver;
      }
    }
  }

  .position {
    width: 7rem;
    position: relative;
    padding-left: 1.4rem;

    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 80%;
      width: 0.4rem;
      left: 0.4rem;
      top: 10%;
    }

    &.champion {
      color: $wet-asphalt;

      &:after {
        background: $wet-asphalt;
      }
    }

    &.final {
      color: $peter-river;

      &:after {
        background: $peter-river;
      }
    }

    &.promotion {
      color: $green-sea;

      &:after {
        background: $green-sea;
      }
    }

    &.repechage {
      color: $wisteria;

      &:after {
        background: $wisteria;
      }
    }

    &.relegation {
      color: $pomegranate;

      &:after {
        background: $pomegranate;
      }
    }

    &.competition-1 {
      color: $amethyst;

      &:after {
        background: $amethyst;
      }
    }

    &.competition-2 {
      color: $orange;

      &:after {
        background: $orange;
      }
    }

    &.top5 {
      width: 4rem;
    }
  }

  .name {
    text-align: left;
  }

  .high {
    color: $black;

    span {
      font-size: 1rem;
      vertical-align: top;
    }
  }

  .number {
    width: 6rem;
  }

  .tb-button {
    text-align: right;
  }

  .pos-change {
    line-height: 1rem;
    font-size: 1rem;

    >div {
      font-size: 1.4rem;

      &.up {
        color: $emerald
      }

      &.down {
        color: $pomegranate
      }
    }
  }

  .sequence {
    >div {
      margin: auto;

      >div {
        float: left;
        height: 0.8rem;
        width: 0.8rem;
        border-radius: 0.4rem;
        margin-left: 0.1rem;

        &.w {
          background-color: $emerald;
        }

        &.d {
          background-color: $silver;
        }

        &.l {
          background-color: $alizarin;
        }
      }
    }
  }

  .competitions-list {
    display: flex;
    justify-content: center;

    .item {
      img {
        height: 3rem;
        width: auto;
        margin: 0 0.3rem;
      }
    }
  }

  .button {
    background: $wet-asphalt;
    height: 3.6rem;
    padding: 0.8rem;
    line-height: 2rem;
    font-size: 1.6rem;
    font-weight: bold;
    border-radius: 0.3rem;

    &:hover {
      background: $pomegranate;
    }

    &.circle {
      border-radius: 50%;
      width: 3.6rem;
    }

    &:disabled,
    &:disabled:hover {
      background: $silver;
    }
  }

  &.dark {
    thead {
      border-bottom: 0.4rem solid $white;

      th {
        color: $clouds;
      }
    }

    tbody {
      tr {
        color: $silver;
        border: 0;

        &:nth-child(odd) {
          background: rgba(150, 150, 150, 0.1);
        }

        &:nth-child(even) {
          background: none;
        }

        &.highlight {
          background: #c9e1f1;
        }

        &:hover {
          background: rgba(150, 150, 150, 0.2);
        }
      }
    }

    .high {
      color: $white;
    }
  }
}