footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 1.2rem;
  border-top: 2px solid $midnight-blue;
  background: $midnight-blue url('/images/header-pattern.png');
  background-size: auto;

  a {
    color: $white;

    &:hover {
      color: $turquoise
    }
  }

  .footer__content {
    height: 14rem;
    overflow: hidden;
    margin: 2rem 0;
    color: $white;

    .footer-about {
      display: flex;

      .footer-logo {
        margin-right: 2rem;
        height: 14rem;

        img {
          height: 100%;
          width: auto;
        }
      }

      .footer-info {
        .description {
          max-height: 9.6rem;
          overflow: hidden;
          margin-bottom: 1rem;
        }

        .social-media {
          overflow: auto;
          display: flex;
          width: 15rem;

          a {
            text-align: center;
            margin-right: 1rem;

            .link {
              height: 3.4rem;
              width: 3.4rem;
              background: #2c3e50AA;
              text-align: center;
              line-height: 3.4rem;
              font-size: 1.5rem;

              &:hover {
                background: #16a08588;
              }

              &:hover {
                color: $white;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    h2 {
      margin: 0;
      margin-bottom: 1rem;
    }

    .stats {
      padding-left: 5rem;
    }
  }
}

@media (max-width: $md-breakpoint) {
  footer {
    .footer__content {
      height: 29.5rem;

      .footer-about {
        margin-bottom: 2rem;
      }

      .stats {
        padding-left: 0.8rem;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  footer {
    .footer__content {
      height: 65.5rem;

      .footer-about {
        display: block;

        .footer-logo {
          margin: 0;
          width: 100%;
          text-align: center;
          margin-bottom: 2rem;
        }

        .footer-info {
          .description {
            text-align: justify;
            max-height: 11.4rem;
          }

          .social-media {
            margin: 2rem auto 1rem auto;

            a {
              .link {
                height: 4rem;
                width: 4rem;
                line-height: 4rem;
                font-size: 2rem;
              }
            }
          }
        }
      }

      .stats {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.footer-legal {
  height: 3.5rem;
  background: $midnight-blue;
  padding: 0.5rem 0;
  text-align: center;
  box-shadow: 0 -0.5rem 0.4rem rgba(0, 0, 0, 0.1);

  .text {
    padding: 0;
    margin: 0.4rem 0;
    font-size: 1.1rem;
    color: $white;
  }
}