.page-placeholder {
  text-align: center;
  margin: 6rem auto;
  color: $concrete;
  max-width: 30rem;

  svg {
    font-size: 7rem;
    margin-bottom: 1rem;
  }

  .text {
    height: 4.4rem;
    color: $asbestos;
    font-size: 1.6rem;
    line-height: 2.2rem;
    overflow: hidden;
  }
}