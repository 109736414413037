//GLOBAL MIXINS

@mixin formatted-list($pvalue, $mvalue,$lstyle) {
  padding: $pvalue;
  margin-top: $mvalue;
  list-style: $lstyle; }

@mixin nav-list() {
  list-style: none;
  padding: 0px;
  margin: 0px;
  li {
    display: inline; } }

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius; }

@mixin go-to-left($width) {
  float: left;
  width: $width; }

@mixin h4-margin($value) {
  margin-block-end: $value;
  margin-block-start: $value; }


@mixin title-underline($display, $content,$pos, $width, $height, $color ) {
  content: $content;
  display: $display;
  position: $pos;
  width: $width;
  height: $height;
  background-color: $color; }

