.competitions-premiums-card {
  padding: 4rem 0;
  margin: 3rem 0;
  background: linear-gradient(to right, $midnight-blue 18rem, #4a6075 64rem);
  box-shadow: 0.5rem 0.5rem 0.6rem rgba(0, 0, 0, 0.03);
  border-radius: 0.3rem;
  display: flex;
  color: $white;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-between;

  .info {
    position: relative;
    text-align: center;
    padding: 0 4rem;
    margin-right: 4rem;
    min-width: 22rem;

    .name {
      font-weight: 400;
      text-transform: uppercase;
    }

    .number {
      font-weight: 900;
      font-size: 7rem;
      line-height: 8.6rem;
    }

    .label {
      font-weight: 400;
      font-size: 1.3rem;
      text-transform: uppercase;
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      background: $white;
      height: 100%;
      width: 0.5rem;
    }
  }

  .details {
    min-width: 48rem;
    width: calc(100% - 46rem);
    display: flex;

    .left {
      width: 26rem;
      overflow: hidden;

      .rewards {
        min-width: 26rem;
        margin-bottom: 0.4rem;
        overflow: hidden;

        .positions {
          float: left;
          color: $silver;
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
          margin-right: 1rem;

          span {
            color: $white;
            font-weight: 900;
          }

          &.first {
            width: 100%;
            font-size: 1.6rem;
          }
        }

        .obs {
          float: left;
          width: 100%;
          font-size: 1.2rem;
          color: $concrete;
        }
      }

      .competition {
        p {
          margin: 0;
          font-size: 1.2rem;
        }
      }
    }

    .teams {
      position: relative;
      min-width: 24rem;
      width: calc(100% - 26rem);
      height: 14rem;
      overflow: hidden;

      .card-all {
        margin-bottom: 1.5rem;
      }

      .multiple-carrossel .indicator {
        margin-bottom: 0;
        margin-top: 2.6rem;
        height: 3rem;
        line-height: 3rem;
      }

      .total {
        position: absolute;
        bottom: 2.2rem;
        left: calc(50% - 10rem);
        width: 20rem;
        font-size: 1.2rem;
        text-align: center;
        color: $silver;
      }
    }
  }

  .buttons {
    text-align: center;
    padding: 1.5rem 4rem;
    min-width: 20rem;

    .price {
      font-weight: 900;
      font-size: 3rem;
      line-height: 3rem;

      &.is-prime {
        color: $silver;
        font-size: 1.8rem;
        line-height: 2.2rem;
        text-decoration: line-through;
      }

      &.only-price {
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
    }

    .price-plus {
      color: $sun-flower;
      margin-bottom: 1rem;
      font-weight: 900;
      font-size: 1.8rem;
      line-height: 2.2rem;

      &.is-prime {
        opacity: 0.9;
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .competitions-premiums-card {
    display: block;

    .info {
      margin-right: 0;
      margin-bottom: 4rem;
      padding-bottom: 4rem;

      .name {
        font-size: 2.2rem;
      }

      &:after {
        top: auto;
        left: 4rem;
        right: auto;
        bottom: 0;
        background: $white;
        height: 0.5rem;
        width: calc(100% - 8rem);
      }
    }

    .details {
      min-width: 100%;
      width: 100%;
      display: block;

      .left {
        width: 100%;
        overflow: auto;

        .rewards {
          min-width: 100%;
          text-align: center;

          .positions {
            width: 100%;
          }
        }

        .competition {
          padding: 2rem 4rem;

          p {
            text-align: center;
          }
        }
      }

      .teams {
        margin: 2rem auto;
        width: 24rem;
      }
    }

    .buttons {
      margin: 4rem 0 2rem 0;
    }
  }
}