.alert-box {
  position: fixed;
  z-index: 100;
  padding: 1.8rem;
  bottom: 0;
  width: 100%;
  color: $white;
  font-size: 1.3rem;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.7);
  animation: slide-up 0.4s ease;
  text-shadow: -0.3rem 0.2rem rgba($black, 0.1);

  &.success {
    background: $green-sea;
  }

  &.error {
    background: $pomegranate;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}