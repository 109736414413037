.title-line {
  position: relative;
  width: 100%;
  font-weight: 900;
  font-size: 3rem;
  line-height: 3.5rem;
  color: $wet-asphalt;
  margin-bottom: 2rem;
  margin-top: 0.5rem;

  &:after {
    content: '';
    display: block;
    border-bottom: 0.4rem solid $wet-asphalt;
    height: 100%;
    width: 7rem;
  }
}

.title-center,
.title-center-menu,
.title-center-banner {
  position: relative;
  font-size: 4.4rem;
  line-height: 5rem;
  font-weight: 700;
  padding: 2rem 0;
  margin: 5rem 0;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    display: block;
    border-bottom: 0.2rem solid $silver;
    bottom: 0;
    width: 10rem;
    margin: 0 calc(50% - 5rem);
  }
}

.title-center-menu {
  margin: 3rem 0;
}

.title-center-banner {
  margin: 1rem 0 3rem 0;
}


@media (max-width: $sm-breakpoint) {

  .title-center,
  .title-center-menu {
    font-size: 3.6rem;
    line-height: 4rem;
    margin: 4rem 0;
    margin-top: 3rem;
  }
}

.right-options {
  margin-bottom: 2rem;
}