.autocomplete {
  input {
    outline: 0;
    border-width: 0 0 0.2rem;
    border-color: $wet-asphalt;
    background: none;

    &:focus {
      border-color: $pomegranate;
    }
  }

  >div>div {
    cursor: pointer;
  }
}