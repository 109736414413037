.soccer-field {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: url('/images/field.svg') no-repeat;
  background-size: 100% auto;
  padding: 6%;

  .positions {
    position: relative;

    .position {
      text-align: center;
      position: absolute;
      width: 25%;
      top: 50%;
      transform: translateY(-50%);

      .mark {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -1.6rem;
        background: $white;
        border-radius: 0.3rem;
        max-width: 7rem;

        .value {
          font-size: 1.1rem;
          padding: 0 0.5rem;
          font-weight: bold;
          color: $nephritis;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      button {
        display: inline-block;
        text-align: center;
        position: relative;
        z-index: 1;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: none;
        background: none;
        color: $nephritis;
        padding: 0;

        &:after {
          pointer-events: none;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          content: '';
          top: 0;
          left: 0;
          z-index: -1;
          -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
          -moz-transition: -moz-transform 0.2s, opacity 0.2s;
          transition: transform 0.2s, opacity 0.2s;
        }
      }

      .player {
        padding: 0;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        svg {
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          color: $white;
          z-index: 2;
        }

        &:after {
          background: $black;
          opacity: 0;
          z-index: 1;
        }

        &:hover {
          svg {
            opacity: 1;
          }

          &:after {
            opacity: 0.5;
          }
        }

        &:before {
          position: absolute;
          content: '';
          border: 0.3rem solid $white-complete;
          display: block;
          border-radius: 50%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
        }
      }

      .plus-add {
        &:after {
          background: $white;
        }

        &:before {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 0.3rem solid $white;
          content: '';
          top: 0;
          left: 0;
          z-index: -1;
          background: none;
        }

        &:hover:after {
          -webkit-transform: scale(0.75);
          -moz-transform: scale(0.75);
          -ms-transform: scale(0.75);
          transform: scale(0.75);
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .soccer-field {
    .positions {
      position: relative;

      .position {
        .mark {
          bottom: -1.4rem;
          border-radius: 0.3rem;
          max-width: 5rem;

          .value {
            padding: 0 0.4rem;
          }
        }

        button {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }
}