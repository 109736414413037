// Colors
$turquoise: #1abc9c;
$green-sea: #16a085;
$emerald: #2ecc71;
$nephritis: #27ae60;
$peter-river: #3498db;
$belize-hole: #2980b9;
$amethyst: #9b59b6;
$wisteria: #8e44ad;
$wet-asphalt: #34495e;
$midnight-blue: #2c3e50;
$sun-flower: #f1c40f;
$orange: #f39c12;
$carrot: #e67e22;
$pumpkin: #d35400;
$alizarin: #e74c3c;
$pomegranate: #c0392b;
$white-complete: #ffffff;
$white: #f8f8f8;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$concrete: #95a5a6;
$asbestos: #7f8c8d;
$black: #2b2b2b;
// Page
$page-width: 120rem;
$md-width: 99.2rem;
$sm-width: 76.8rem;
$xs-width: 57.6rem;
// breakpoints
$breakpoint-multiplier: 0.625;
$md-breakpoint: 992px;
$sm-breakpoint: 768px;
$xs-breakpoint: 576px;
//Font-Family
$font-family-title: 'Roboto', Helvetica, Arial, sans-serif;
$font-family-text: 'Open Sans', Helvetica, Arial, sans-serif;
// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$xs-size: 1rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;