.collectibles {
  .chest {
    position: relative;
    margin-bottom: 3rem;

    .content {
      position: relative;
      display: flex;
      background: $wet-asphalt;
      color: $white;
      border-radius: 0.3rem;

      .image {
        position: relative;
        padding: 2rem;
        width: 16rem;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 0.3rem;

        img {
          position: relative;
          width: 100%;
          height: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .informations {
        padding: 2rem;
        width: calc(100% - 16rem);

        .name {
          font-weight: 500;
        }

        .progress {
          height: 0.8rem;
        }

        .total {
          text-align: right;
          font-size: 1.2rem;
          margin: 0.2rem 0 0.6rem 0;
        }

        .buttons {
          text-align: center;

          .button {
            font-size: 1.5rem;
            margin: 0.8rem 0.4rem;

            &.buy {
              background: $amethyst;

              &:hover {
                background: $peter-river;
              }
            }

            &.open {
              background: $green-sea;

              &:hover {
                background: $turquoise;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .collectibles {
    .chest {
      .content {
        .image {
          padding: 1.5rem;
          width: 10rem;
        }

        .informations {
          padding: 1.5rem;
          width: calc(100% - 10rem);
        }
      }
    }
  }
}