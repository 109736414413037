.news-page {
  margin-bottom: 4rem;

  .item {
    background: #fff;
    margin: 1rem;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 3rem;
    overflow: hidden;

    .image {
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      padding-bottom: 62%;

      .info {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        background: #00000088;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        color: $white;
        line-height: 100%;
        font-weight: bold;

        svg {
          margin-right: 0.5rem;

          &:last-child {
            margin-left: 2rem;
          }
        }
      }

      &:hover {
        .info {
          display: flex;
        }
      }
    }

    .texts {
      border-top: solid 0.2rem $wet-asphalt;
      background: #fff;
      position: relative;
      width: 100%;
      padding: 2rem 1rem;
      text-align: center;

      .title {
        width: 100%;
        color: $belize-hole;
        line-height: 2.2rem;
        font-size: 1.3rem;
        padding-bottom: 1rem;
        font-weight: 900;
        text-transform: uppercase;
      }

      .description {
        position: relative;
        width: 100%;
        color: $black;
        line-height: 2.2rem;
        padding-top: 1.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 8.1rem;
        font-weight: bold;
        font-size: 1.2rem;

        &:before {
          content: '';
          width: 10rem;
          height: 0.1rem;
          ;
          background: $clouds;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -5rem;
          float: left;
        }
      }
    }

    &:hover,
    &:active {
      background: $clouds;

      .image {
        transform: scale(1.25);
      }

      .texts {
        .title {
          color: $green-sea;
        }
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .news-page {
    .item {
      margin: 1rem 0 3rem 0;
    }
  }
}