$game-details-logo-width: 10rem;

.game-header {
  position: relative;
  overflow: hidden;
  padding: 4rem 0;
  background: $wet-asphalt url('/images/header_game.jpg') center;
  background-size: cover;

  .content-container {
    position: relative;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-color: #24252f;
    opacity: 0.92;
    left: 0;
  }

  .game-title {
    text-align: center;
    margin-bottom: 1rem;

    .title {
      line-height: 5rem;
      font-size: 4.8rem;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      margin: 1.4rem 0;
      padding: 0;
    }

    .sub-title {
      font-size: 14px;
      color: #fec722;
      font-weight: 400;
    }
  }

  .game-left {
    float: left;
    text-align: left;

    img {
      margin-right: 3rem;
    }
  }

  .game-right {
    float: right;
    text-align: right;

    img {
      margin-left: 3rem;
    }
  }

  .game-team {
    .badge {
      float: left;

      img {
        width: 10rem;
        height: 10rem;
      }
    }

    .team {
      margin-top: 1rem;
      float: left;
      max-width: calc(100% - 13rem);

      .name {
        line-height: 5.4rem;
        font-size: 3.4rem;
        color: $white;
        font-weight: 700;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.low {
          font-size: 2rem;
        }
      }

      .tag {
        font-size: 1.4rem;
        color: $silver;
        font-weight: 400;
        margin: 0;
        line-height: 12px;
      }
    }
  }

  .team-vs-team {
    text-align: center;
    position: relative;

    .value {
      position: relative;
      display: block;
      font-size: 5rem;
      color: $white;
      font-weight: 400;
      border: 2px solid #45464f;
      border-radius: 50%;
      width: 12.7rem;
      height: 12.7rem;
      margin: 0 auto;
      line-height: 12.5rem;
      background: #30313b;

      &.low {
        font-size: 4rem;
      }

      span {
        font-size: 2.6rem;
        margin: 0 0.2rem;
        vertical-align: top;
      }
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 0.2rem;
      background: #44454d;
      left: 0;
      right: 0;
      bottom: 6rem;
      margin: 0 auto;
    }
  }

  .game-bottom {
    text-align: center;
    margin: 3rem 0 0 0;

    .row {
      font-size: 1.4rem;
      color: #acadb1;
      font-weight: 400;
      letter-spacing: 0.1rem;

      span {
        margin-left: 0.5rem;
        color: $white;
      }
    }
  }
}

.game-details {
  .field {
    margin: 3rem;
    margin-bottom: 1rem;
    overflow: auto;

    .formation {
      float: left;
      width: 100%;
      position: relative;
      padding-top: 134%;

      .player {
        display: inline-block;
        text-align: center;
        position: relative;
        z-index: 1;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        border: none;
        background: none;
        color: $nephritis;
        padding: 0;
      }
    }
  }
}


@media (max-width: $md-breakpoint) {
  .game-details {
    .field {
      margin-left: auto;
      margin-right: auto;

      .formation {
        .player {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }
}

$game-bets-single-height: 4rem;
$game-bets-single-bar-height: 1rem;

.game-bets-single {
  margin: 3rem 0;

  .game {
    width: 100%;
    height: $game-bets-single-height;
    margin-bottom: 1rem;

    >div {
      float: left;
    }

    .bet {
      width: 6.5rem;

      .bet-score {
        line-height: $game-bets-single-height - $game-bets-single-bar-height;
        font-size: 1.6rem;
        width: 100%;
        text-align: center;
        font-weight: bold;
      }

      .goals {
        line-height: $game-bets-single-bar-height;
        font-size: $game-bets-single-bar-height;
        width: 100%;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;

        svg {
          margin: 0 0.2rem;
        }
      }
    }

    .score {
      width: 20rem;
      height: $game-bets-single-height;

      .logo {
        float: left;
        height: 100%;
        width: $game-bets-single-height + 1rem;
        text-align: center;

        img {
          height: 100%;
        }
      }

      .value {
        float: left;
        height: 100%;
        width: 20rem - 2 * ($game-bets-single-height + 1rem);
        text-align: center;
        line-height: $game-bets-single-height;
        font-size: $game-bets-single-height * 0.7;
        font-weight: bold;
      }

      @media (max-width: $xs-breakpoint) {
        width: 16rem;

        .value {
          width: 16rem - 2 * ($game-bets-single-height + 1rem);
          font-size: $game-bets-single-height * 0.5;
        }
      }
    }

    .bar {
      width: calc(50% - 16.5rem);
      height: 100%;

      .name {
        line-height: $game-bets-single-height - $game-bets-single-bar-height;
        font-size: 1.6rem;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media (max-width: $xs-breakpoint) {
          text-overflow: clip
        }

        ;

        &__right {
          text-align: right;
        }
      }

      .progress {
        height: $game-bets-single-bar-height;
        width: 100%;
      }

      @media (max-width: $xs-breakpoint) {
        width: calc(50% - 14.5rem);
      }
    }
  }
}

$game-bets-market-team-width: 8rem;
$game-bets-market-charts-width: 8rem;

.game-bets-card {
  margin: 3rem 0;

  .game {
    width: 100%;
    margin-bottom: 1rem;
    background: $clouds;
    border-radius: 1.2rem;
    margin: 1rem 0;
    padding-bottom: 1rem;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .team {
      float: left;
      width: $game-bets-market-team-width;
      overflow: hidden;
      padding: 1rem 1rem 0 1rem;

      .goals {
        width: 100%;
        line-height: 1.2rem;
        height: 1.2rem;
        text-align: center;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        overflow: hidden;
      }
    }

    .result {
      float: left;
      width: calc(100% - #{$game-bets-market-team-width * 2});
      overflow: hidden;

      h5 {
        text-align: center;
        margin: 0;
      }

      .bar-chart {
        height: 6rem;
        margin: auto;
        width: $game-bets-market-charts-width;
      }

      .progress {
        height: 0.8rem;
        margin: auto;
        width: $game-bets-market-charts-width - 2rem;
        margin-bottom: 1rem;
      }
    }

    .team-score {
      float: left;
      width: $game-bets-market-team-width;
      overflow: hidden;
      min-height: 0.1rem;

      >div {
        font-size: 1.3rem;
        line-height: 1.5rem;
        text-align: center;
        position: relative;

        span {
          position: absolute;
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          top: calc(50% - 0.5rem);
          right: 0rem;
        }
      }

      &__right {
        >div {
          span {
            right: auto;
            left: 0rem;
          }
        }
      }
    }

    .round-result {
      float: left;
      width: calc(100% - #{$game-bets-market-team-width * 2});
      overflow: hidden;

      >div {
        font-size: 1.3rem;
        line-height: 1.5rem;
        text-align: center;
        position: relative;
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .game-header {
    .game-left,
    .game-right {
      display: flex;
      margin: 2.5rem auto;
      justify-content: center;
      float: left;
      text-align: left;
    }

    .game-team {
      .badge {
        margin: 0;
        order: 1;

        img {
          margin-right: 2rem;
          margin-left: 0;
        }
      }

      .team {
        max-width: calc(100% - 12rem);
        margin: 0;
        order: 2;
      }
    }
  }

  .game-details {
    background: $clouds;
  }
}