.achievements-list {
  .top3-header {
    position: relative;
    padding: 0;
    background: $black url('/images/team_badges.jpg') no-repeat;
    background-position: center;
    background-size: cover;

    .podium {
      margin: auto;
      width: 50rem;
      max-width: 100%;
      height: 45rem;
      background: url('/images/achievements_top3.png') center top no-repeat;

      .teams {
        width: 38rem;
        height: 100%;
        margin: auto;
        display: flex;

        .team {
          filter: drop-shadow(0.4rem 0.4rem 0.8rem #222);
          padding: 0 1rem;

          img {
            width: 100%;
            height: auto;
          }

          &.first {
            margin-top: 15.5rem;
            width: 14rem;
            padding: 0;
          }

          &.second {
            margin-top: 22.5rem;
            width: 13rem;
            padding: 0 1rem;
          }

          &.third {
            margin-top: 28rem;
            width: 11rem;
            padding: 0 2rem;
          }
        }
      }
    }
  }
}