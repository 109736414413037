$menu-secondary-height: 4.8rem;

.menu-secondary {
  background: $white;
  overflow-x: auto;

  .wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .item {
      position: relative;
      display: flex;
      min-width: 15.6rem;
      border-right: 0.2rem solid $clouds;

      &:first-child {
        border-left: 0.2rem solid $clouds;
      }

      a {
        width: 100%;
        border-bottom: 0.6rem solid $white;

        .content {
          color: $concrete;
          text-align: center;
          padding: 2rem 1rem 1.3rem 1rem;
          text-transform: uppercase;
          overflow: hidden;

          .tag {
            font-size: 1.1rem;
            line-height: 1.1rem;
            font-weight: 600;
          }

          .name {
            width: 100%;
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 900;
          }
        }
      }

      .selected {
        .content {
          .name {
            color: $wet-asphalt;
          }
        }

        &:after {
          position: absolute;
          content: '';
          display: block;
          background: $pomegranate;
          width: calc(100% + 0.4rem);
          height: 0.6rem;
          left: -0.2rem;
        }
      }

      &:hover {
        a {
          .content {
            .name {
              color: $midnight-blue;
            }
          }

          &:after {
            position: absolute;
            content: '';
            display: block;
            background: $wet-asphalt;
            width: calc(100% + 0.4rem);
            height: 0.6rem;
            left: -0.2rem;
          }
        }
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .menu-secondary {
    .content-container {
      padding: 0;
    }
  }
}
