h1 {
  font-size: 2.6rem;
  display: inline-block; }

h1::after {
  @include title-underline(block, '', none, 65%, 0.5rem, $white); }

h3 {
  font-size: 2rem;
  text-align: left;
  margin: 0; }

h4 {
  font-size: 1.6rem;
  text-align: left;
  margin: 0; }
