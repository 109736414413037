.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10002;
  bottom: 0;
  background: rgba(0, 0, 0, .2);

  .content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    img {
      height: 6rem;
      width: 6rem;
    }
  }
}

.loader-launch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10003;
  bottom: 0;
  background-image: radial-gradient(#2E4254, #1F2C38);

  &.hide {
    display: none;
  }

  .content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    width: 20rem;

    .logo {
      width: 20rem;
      height: auto;
    }

    .loading {
      width: 8rem;
      height: auto;
    }
  }
}