.messages-wrapper {

  .button {
    margin: 0.5rem 0;
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 20rem;
    border: 0.1rem #dfe1e2 solid;
    resize: none;
    padding: 2rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: $belize-hole;

    &:focus {
      outline-width: 0;
    }
  }

  .inbox-header {
    text-align: center;
    margin-bottom: 0.5rem;

    .buttons {
      display: flex;

      .button {
        margin: 0.5rem 0.2rem;
      }
    }
  }

  .inbox-list {
    .card-simple {
      margin-bottom: 0.5rem;
      padding: 1rem 2rem;
    }

    a {
      &:nth-child(odd) {
        .card-simple {
          background: $clouds;
        }
      }

      &:hover {
        .card-simple {
          background: #dfe1e2;
        }
      }
    }

    .line {
      display: flex;

      >div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1.4rem;
        color: $asbestos;
      }

      &.new {
        font-weight: 900;
      }

      svg {
        margin-right: 0.6rem;
      }

      .users {
        width: 20%;
        padding-right: 1rem;
        color: $belize-hole;
      }

      .message {
        width: calc(80% - 9rem);
        padding-right: 1rem;
      }

      .date {
        width: 9rem;
      }
    }

  }

  .inbox-new {
    .from {
      display: flex;
      overflow: hidden;
      margin-bottom: 2rem;

      .filter-select {
        margin: 0.5rem 1rem 0.5rem 0;
      }

      .button {
        width: auto;
        padding: 0.5rem 2rem;
        margin-left: 1.5rem;
      }
    }

    .autocomplete {
      margin-top: 0.8rem;
    }
  }

  .list-messages {
    .users {
      height: 6.6rem;
      margin: 1.5rem 0 1rem 0;

      button:disabled {
        transition: none;
        opacity: 0.4;
      }

      .button-action {
        height: 80%;
      }

      .item {
        padding: 0 0.8rem;
      }
    }

    textarea {
      margin: 1rem 0;
      height: 6rem;
      padding: 2rem;

      &.has-content {
        height: 20rem;
      }
    }

    .message {

      .date {
        margin-top: 0.5rem;
        font-size: 1.3rem;
        color: $concrete;

        .delete {
          cursor: pointer;
          margin: 0.6rem;
          color: $asbestos;
          font-weight: 600;

          &:hover {
            color: $black;
          }
        }
      }

      .text {
        width: 80%;
        color: $asbestos;
        overflow: hidden;
        font-size: 1.5rem;
        white-space: pre-wrap;
      }

      a {
        color: $belize-hole;
        font-weight: bold;

        &:hover {
          color: $wet-asphalt;
        }
      }

      &.is-user {
        text-align: right;

        .card-simple {
          background: $clouds;
        }

        .text {
          margin-left: 20%;
        }
      }
    }

    .card-simple {
      margin-bottom: 0.5rem;
      padding: 1rem 2rem;
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .messages-wrapper {
    .inbox-list {
      .line {
        .users {
          width: 30%;
        }

        .message {
          width: calc(70% - 7rem);
        }

        .date {
          width: 7rem;
        }
      }
    }
  }
}