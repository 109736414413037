html {
  font-size: 62.5%;
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

body {
  background: $clouds url('/images/bg_texture.jpg');
  position: relative;
  margin: 0;
  min-height: 100%;
  color: $midnight-blue;
  font-family: $font-family-title;
  font-size: $m-size;
  line-height: 1.6;
  padding-bottom: 21.5rem;

  @media (max-width: $md-breakpoint) {
    padding-bottom: 37rem;

    &.activeDropdown {
      position: fixed;
      overflow: hidden;
    }
  }

  @media (max-width: $xs-breakpoint) {
    padding-bottom: 73rem;
  }
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.is-active {
  font-weight: bold;
}

.hidden {
  display: none;
}

.recharts-wrapper * {
  -o-transition: all 0 ease-out;
  transition: none;
}

.img-align-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0.8; }
}