.landing-header {
  position: relative;
  background: #131a24 url('/images/landing/header.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  color: $white;

  .top {
    padding-top: 4rem;
    display: table;
    width: 100%;

    .logo {
      display: table-cell;
      height: 15.8rem;
      width: 17rem;

      img {
        display: block;
        height: 100%;
        width: auto;
      }
    }

    .links {
      display: table-cell;
      height: 15.8rem;
      text-align: right;
      vertical-align: middle;

      a,
      button {
        margin: 1rem 1.5rem;
        font-size: 1.5rem;
        border-radius: 0.3rem;
        padding: 1.2rem 0;

        &:hover,
        &:active {
          background: none;
          color: $green-sea;
        }

        &.register {
          background: #2980b9AA;
          padding: 1.2rem 2.5rem;
          margin-right: 0;
          margin-left: 2.5rem;

          &:hover,
          &:active {
            color: $white;
            background: #16a08588;
          }
        }
      }
    }
  }

  .content-header {
    position: relative;
    padding: 7rem 12rem;
    height: calc(100vh - 23.8rem);
    min-height: 54rem;
    overflow: hidden;
    width: 100%;

    .wrapper {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 44rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 300;

    .phrase {
      flex-grow: 1;

      .phrase-content {
        padding: 5rem 8rem 5rem 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .text {
          font-size: 2.8rem;

          .phrase-highlight {
            all: unset;
            font-weight: 900;
          }
        }

        .author {
          font-size: 2rem;
          text-align: right;
          font-style: italic;
          margin-top: 1rem;
        }
      }
    }

    .login {
      position: relative;
      width: 100%;
      max-width: 42rem;
      height: 44rem;
      background: $white;
      padding: 5rem;
      border-radius: 0.3rem;
      color: $asbestos;

      a {
        color: $peter-river;       

        &:hover {
          color: $green-sea;
        }
      }

      .title {
        line-height: 2.4rem;
        font-size: 1.8rem;
        margin-bottom: 1.8rem;

        b {
          font-weight: bold;
          font-size: 2rem;
        }
      }

      .input {
        position: relative;
        margin-bottom: 1.5rem;

        input {
          width: 100%;
          border: #d8dcdf 0.1rem solid;
          background: $white;
          border-radius: 0.2rem;
          padding-left: 5.8rem;
        }

        .icon {
          position: absolute;
          left: 0;
          top: 0.2rem;
          width: 4.4rem;
          text-align: center;
          line-height: 4rem;
          border-right: #d8dcdf 0.1rem solid;

        }
      }

      .button {
        width: 100%;
        background: $emerald;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 2rem;

        &:hover {
          background: $green-sea;
        }
      }

      .forget-password {
        text-align: right;
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
      }

      .register {
        font-weight: 500;
        text-align: center;
        font-size: 1.2rem;
      }
    }
  }
}

@media (max-width: $md-breakpoint) {
  .landing-header {
    .content-header {
      padding: 7rem 1rem;
    }

    .content {
      justify-content: center;

      .phrase {
        display: none;
      }
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .landing-header {
    padding-bottom: 3rem;
    .top {
      padding-top: 2rem;
      display: block;

      .logo {
        display: block;
        height: 12.8rem;
        width: 100%;
        margin-bottom: 2rem;

        img {
          margin: auto;
        }
      }

      .links {
        display: block;
        height: 5.1rem;
        text-align: center;

        a,
        button {
          margin: 1rem;

          &.register {
            margin: 1rem 1.5rem;
          }
        }
      }
    }

    .content-header {
      min-height: 46rem;
    }

    .content {
      height: 41rem;

      .login {
        padding: 2.5rem;
        height: 41rem;
      }
    }
  }
}

.landing-about {
  position: relative;
  background: #c72f2d url('/images/landing/about.jpg');
  min-height: 30rem;
  padding: 5rem 3rem;
  color: $white;

  &:before {
    content: "";
    background: url('/images/landing/about_field.png') no-repeat;
    background-size: auto 100%;
    background-position: left;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0.3;

  }

  .top {
    display: table;
    margin-bottom: 5rem;

    .logo {
      height: 32.4rem;
      width: 35.1rem;
      display: table-cell;

      img {
        margin: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .title {
      display: table-cell;
      vertical-align: middle;
      padding-left: 5rem;
      font-size: 4.2rem;
      line-height: 5.4rem;
      text-transform: uppercase;
      letter-spacing: -0.2rem;

    }
  }

  .content {
    font-size: 1.8rem;
  }
}

@media (max-width: $sm-breakpoint) {
  .landing-about {
    .top {
      display: block;

      .logo {
        display: block;
        width: 100%;
        height: 25.6rem;
        margin-bottom: 5rem;
        text-align: center;
      }

      .title {
        text-align: center;
        display: block;
        padding-left: 0;
        font-size: 3.2rem;
        line-height: 4rem;
      }
    }
  }
}


.landing-game-modes {
  position: relative;
  background: #ecf0f1 url('/images/landing/game_modes.jpg') no-repeat;
  background-size: auto 100%;
  background-position: right;
  padding: 20rem 0;
  color: $black;

  .modes {
    margin-right: 25rem;
  }

  .mode {
    margin: 0 0.5rem;
  }

  .logo {
    height: 22rem;
    text-align: left;
    width: 100%;
    border-radius: 0.3rem;
    padding: 3rem 0;

    svg,
    img {
      height: 100%;
      width: auto;
    }

    svg path {
      fill: $black;
    }
  }

  .title {
    font-size: 2.2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: left;
  }

  .text {
    font-size: 1.6rem;
    text-align: left;
  }
}

@media (max-width: $md-breakpoint) {
  .landing-game-modes {
    background: #ecf0f1;

    .modes {
      margin-right: 0rem;
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .landing-game-modes {
    padding: 3rem 0 5rem 0;

    .logo {
      text-align: center;
    }

    .title {
      text-align: center;
    }

    .text {
      text-align: center;
    }
  }
}