.team-calendar {

  .calendar-card {

    &.past {
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }

    .tag {
      position: absolute;
      font-weight: 700;
      text-align: center;
      background: none;
      line-height: 2.8rem;
      font-size: 1.4rem;
      top: -1rem;
      left: 5%;
      right: 5%;
      padding: 0 1rem;
      color: $clouds;
      border-radius: 0.2rem;
      background: $pomegranate;

      .hover {
        display: none;
      }
    }

    .number {
      margin-top: 1rem;
      margin-bottom: 3rem;
      color: $black;
      font-size: 6rem;
      line-height: 6rem;
      font-weight: bold;
      text-align: center;
      text-shadow: -0.3rem 0.2rem rgba($black, 0.1);
    }

    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: 0.5rem 1rem;
      color: $clouds;
      font-size: 1.4rem;
      line-height: 2.4rem;
      width: 100%;
      font-weight: bold;
      opacity: 0.8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 0.2rem;

      .hover {
        display: none;
      }
    }

    &.hovered {

      .card-simple {
        .tag {
          box-shadow: rgba(0, 0, 0, 0.5) 0.4rem 0 2rem;

          .value {
            display: none;
          }

          .hover {
            display: block;
          }
        }

        .number {
          color: $clouds;
        }

        .text {
          opacity: 1;
        }

      }

    }

  }

}


@media (max-width: $xs-breakpoint) {
  .team-calendar {
    .calendar-card {  
      .tag {
        font-size: 1.2rem;
      }
  
      .number {
        letter-spacing: -0.2rem;
        font-size: 5rem;
      }
  
      .text {
        font-size: 1.2rem;
      }
  
    }
  
  }
}