.button {
  background: none;
  border: none;
  color: white;
  display: inline-block;
  font-size: $font-size-large;
  font-weight: 300;
  line-height: 1;
  padding: #{$s-size + 0.3rem};
  text-decoration: none;

  &.active, &:hover {
    background: $wet-asphalt;
  }

  &:focus {
    outline: none;
  }

  &.normal {
    background: $wet-asphalt;
    font-weight: bold;
    border-radius: 0.3rem;
    padding: $s-size;

    &.active, &:hover {
      background: $belize-hole;
    }

    &:disabled, &:disabled:hover {
      background: $silver;
    }
  }

  &.red {
    background: $pomegranate;

    &.active, &:hover {
      background: $wet-asphalt;
    }
  }

  &.light-blue {
    background: $belize-hole;

    &.active, &:hover {
      background: $peter-river;
    }
  }

  &.blue {
    background: $belize-hole;
  }

  &.highlight {
    background: $wet-asphalt;

    &.active, &:hover {
      color: $peter-river;
    }
  }
}

.button--link {
  background: none;
}

.button--secondary {
  background: #888;
}