.simple-header {
  background: url('/images/bg_2.jpg');
  margin: 0;

  .overlap {
    padding-bottom: 0;
    border-top: 0.3rem solid $midnight-blue;
    background-color: rgba(44, 62, 80, 0.85);
    box-shadow: 0 -10px 10px -10px #999 inset;

    h3 {
      text-align: center;
      line-height: 8rem;
      margin: 0;
      color: $white;
    }
  }
}

.lines-header {
  background: url('/images/bg_2.jpg') center;

  .overlay {
    background-color: rgba(#333, 0.85);
    box-shadow: 0 -10px 10px -10px #999 inset;
  }

  .back-lines {
    padding: 2rem 0;
    background-repeat: repeat-y;
    background-image: url('/images/line_background.png');
    background-size: 100% 3.5rem;
    background-position: center;
  }

  .info {
    position: relative;
    padding: 1rem 15%;
    overflow: hidden;
    display: table;
    height: 12.8rem;
    width: 100%;

    .logo {
      display: table-cell;
      width: 15.8rem;

      img {
        margin-right: 3rem;
        width: 12.8rem;
        height: auto;
        -webkit-filter: drop-shadow(0.4rem 0.4rem 0.8rem #222);
        filter: drop-shadow(0.4rem 0.4rem 0.8rem #222);
      }
    }

    .name {
      text-align: left;
      position: relative;
      display: table-cell;
      vertical-align: middle;
      padding: 0.6rem 0;
      color: #555;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 5.6rem;
      line-height: 5.6rem;
      font-weight: 800;
      text-shadow:
        0.2rem 0 0 $white, -0.2rem 0 0 $white, 0 0.2rem 0 $white,
        0 -0.2rem 0 $white, 0.1rem 0.1rem $white, -0.1rem -0.1rem 0 $white,
        0.1rem -0.1rem 0 $white, -0.1rem 0.1rem 0 $white,
        0.4rem 0.4rem 0.8rem #222;
      text-overflow: ellipsis;

      .sub-name {
        font-size: 1.8rem;
        line-height: 3rem;
        color: $white;
        font-weight: 500;
        text-shadow:
        0.2rem 0 0 #555, -0.2rem 0 0 #555, 0 0.2rem 0 #555,
        0 -0.2rem 0 #555, 0.1rem 0.1rem #555, -0.1rem -0.1rem 0 #555,
        0.1rem -0.1rem 0 #555, -0.1rem 0.1rem 0 #555,
        0.4rem 0.4rem 0.8rem #222;
      }
    }

    .buttons {
      position: relative;
      display: table-cell;
      text-align: right;
      vertical-align: middle;

      .button {
        margin: 0.4rem auto;
        margin-left: 3rem;
        padding: 1rem;
        box-shadow: 0.1rem 0.1rem 0.8rem rgba(0, 0, 0, 0.3);
        font-weight: 400;
        border-radius: 0.4rem;
        border: 0.2rem solid rgba(0, 0, 0, 0);
        padding: 1rem 1.2rem;

        &:hover {
          border: 0.2rem solid $clouds;
        }

        svg {
          margin-left: 1rem;
        }
      }
    }
  }
}



@media (max-width: $md-breakpoint) {
  .lines-header {
    .info {
      padding: 1rem;
      display: inherit;
      text-align: center;
      height: 100%;

      .logo {
        display: inline-block;
        width: 100%;

        img {
          margin-right: 0;
        }
      }

      .name {
        display: inline-block;
        width: 100%;
        font-size: 5rem;
        line-height: 5rem;
        text-align: center;
      }

      .buttons {
        display: inline-block;
        text-align: center;

        .button {
          margin: 0.4rem 1rem;
        }
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .lines-header {
    .info {
      .name {
        font-size: 3.8rem;
        line-height: 3.8rem;
      }
    }
  }
}