.dropdown {
  top: 100%;
  position: absolute;
  margin: 0;
  z-index: 20;
  width: 16rem;
  background: #2c3e50;
  background-size: auto;
  box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.5);

  &:before {
    content: "";
    background: #34495e url(/images/header-texture.png) repeat;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0.3;
  }

  ul {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;

    svg {
      margin: 0 0.5rem;
    }

    .button {
      width: 100%;
      background: none;
      font-size: 1.6rem;
      line-height: 1.4rem;
      text-align: center;
      padding: 1.3rem 1.5rem;
      cursor: pointer;

      &:hover {
        background: #212b3c;
      }
    }
  }


  .close {
    display: none;
  }

  .logo {
    display: none;
  }

  .title {
    display: none;
  }

  .message {
    display: none;
  }

  .alert {
    position: relative;
    text-align: center;
    font-size: 1rem;
    line-height: 3rem;
    background: #c0392bDD;
    text-transform: uppercase;
  }

  mark {
    margin-left: 1rem;
    top: auto;
    right: auto;
    background: $alizarin;
  }
}

@media (max-width: $md-breakpoint) {
  .dropdown {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    overflow: auto;
    padding-bottom: 2rem;
    padding-top: 8rem;

    ul {

      li {
        width: 30rem;
        margin: auto;

        .button {
          color: $white-complete;
          font-weight: 700;
          font-size: 2rem;
          text-transform: uppercase;
          padding: 1.2rem;
          line-height: 2rem;
          text-align: left;

          &:hover {
            color: $pomegranate;
            background: none;
          }
        }
      }
    }

    .close {
      display: block;
      cursor: pointer;
      position: absolute;
      width: 8rem;
      height: 8rem;
      right: 0;
      top: 0;
      line-height: 8rem;
      text-align: center;
      font-size: 2.4rem;
      text-decoration: none;
      background: none;
      color: $white-complete;
      border: 0;

      &:focus {
        outline: none;
      }

      &:hover {
        background: $wet-asphalt;
      }
    }

    .logo {
      display: block;
      text-align: center;

      img {
        color: $white-complete;
        height: 8rem;
        width: auto;
      }
    }

    .title {
      display: block;
      color: $white-complete;
      text-align: center;
      font-size: 3.8rem;
      line-height: 3.8rem;
      text-transform: uppercase;
      font-weight: 900;
      margin: 2rem 0;
    }

    .message {
      display: block;
      color: $white-complete;
      text-align: center;
      font-size: 1.6rem;
      line-height: 1.6rem;
      margin: 2rem 0 4rem 0;
    }

    .alert {
      margin-bottom: 1rem;
      font-size: 1.3rem;
    }
  }
}