.competitions-closed {
  margin: 3rem 0;

  .team-content {
    margin-top: 1rem;
    height: 5.8rem;

    &.height-auto {
      height: auto;
    }

    >.label {
      color: $concrete;
      font-size: 1.4rem;
      font-weight: bold;

      span {
        color: $midnight-blue;
      }
    }

    .strikers {
      display: block;
      height: 3.6rem;
      line-height: 3.6rem;

      img {
        margin-right: 0.6rem;
        height: 3.6rem;
      }
    }

    .button {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.competitions-empty {  
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-align: center;
}