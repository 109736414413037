.tutorial-wrapper {
  color: $midnight-blue;
  position: relative;
  margin: 5rem auto;
  padding: 0;
  background: $white;
  border-radius: 0.3rem;
  display: flex;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.15);
  font-size: 1.5rem;

  i {
    font-size: 1.3rem;
  }

  .image {
    width: 50%;
    background-position: center;
    background-size: cover;
  }

  .content {
    width: 50%;
    padding: 5rem;
    min-width: 50rem;
    text-align: center;

    &.full {
      width: 100%;
    }

    .title-center {
      margin-top: 0;
    }

    .text {
      margin-bottom: 7rem;
    }

    .text-center {
      text-align: center;
      margin: 3rem 0;
    }

    p {
      text-align: justify;

      &.center {
        text-align: center;
      }
    }
    

    .list-number {
      text-align: justify;
      margin-right: 3rem;
      font-size: 1.4rem;
    }

    .tip,
    .error {
      font-size: 1.1rem;
    }

    .error {
      position: absolute;
      color: $alizarin;
    }

    .section {
      margin-bottom: 4rem;
      text-align: left;

      .section-content {
        margin: 1rem 0;

        textarea {
          width: calc(100% - 3rem);
          height: 14rem;
          border: 0.1rem #dfe1e2 solid;
          resize: none;
          padding: 2rem;
          border-radius: 0.3rem;
          margin-bottom: 1rem;
          color: $belize-hole;
          font-size: 1.5rem;


          &:focus {
            outline-color: #e6e6e6;
          }
        }

        input {
          background: none;
          cursor: text;
          height: 3.5rem;
          border: none;
          border-bottom: 0.2rem solid #e6e6e6;
          width: calc(50% - 3rem);
          margin-right: 3rem;
          font-size: 1.5rem;
          color: $belize-hole;

          &.all {
            width: calc(100% - 3rem);
          }

          &::placeholder {
            font-size: 1.3rem;
          }

          &:focus {
            outline: none;
            border-bottom: 2px solid $silver;

            &::placeholder {
              color: transparent;
            }
          }
        }

      }

      &.cart {
        text-align: center;

        .logo-item {
          img {
            height: 14rem;
            width: auto;
          }
        }

        h4 {
          text-align: center;
          font-size: 2.4rem;
        }

        .value {
          font-size: 5rem;
          font-weight: 900;
        }
      }
    }

    ul {
      padding-left: 0;
      margin: 0;
      font-size: 1.3rem;
      list-style-type: none;

      &.list {
        list-style-type: disc;
        padding-left: 3rem;
        text-align: left;
      }
    }

    .button {
      margin: 0 1rem;
    }

    .scores {
      .score {
        display: flex;
        margin: 1rem 0;

        .color {
          width: 0.6rem;
        }

        .texts {
          width: calc(100% - 0.6rem);
          padding: 0.5rem 1rem;
          text-align: left;

          h5 {
            margin: 0;
            font-size: 1.5rem;
          }

          p {
            margin: 0;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .tutorial-wrapper {
    .image {
      display: none;
    }

    .content {
      width: 100%;
      min-width: 0;
    }
  }
}

@media (max-width: $xs-breakpoint) {
  .tutorial-wrapper {
    margin: 3rem auto;

    .content {
      padding: 3rem;
      padding-bottom: 4rem;

      p {
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 3rem;
      }

      .list-number {
        font-size: 1.3rem;
        text-align: left;
      }

      .section {
        .section-content {
          input {
            width: 100%;
            margin: 1rem 0;
          }

          textarea {
            width: 100%;
          }
        }
      }

      .button {
        margin: 0.5rem 1rem;
      }
    }
  }
}